import React from "react";
import { TaskForm } from "../Components/TaskForm/TaskForm";
import { useDispatch, useSelector } from "react-redux";
import { useAddTaskMutation } from "../storage/services/tasks";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setTaskHeader, setTemplate } from "../storage/headerInfoSlice";

const AddTask = () => {
  const settings = useSelector((state) => state.settings);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitTask, state] = useAddTaskMutation();
  const submitForm = (data) => {
    let sumSquare =
      parseInt(data["площадь_подвал"]) +
      parseInt(data["площадь_цоколь"]) +
      parseInt(data["площадь_первый"]) +
      parseInt(data["площадь_второй"]) +
      parseInt(data["площадь_третий"]);
    if (sumSquare > 0) {
      submitTask(data);
    } else {
      alert("Общая площадь должна быть больше нуля");
    }
  };

  if (settings.error) {
    return <>Ошибка</>;
  }

  if (state.isSuccess) {
    alert("Объект успешно добавлен с id " + state.data.id);
    dispatch(setTaskHeader(state.originalArgs["задание_на_оценку"]));
    dispatch(setTemplate(state.originalArgs["шаблон_отчета"]));
    sessionStorage.removeItem("savedID");
    navigate("/EditObject", {
      state: { key: state.originalArgs["задание_на_оценку"] },
    });
  }

  if (Object.keys(settings.settings).length > 0)
    return (
      <>
        <Typography variant="h5" align="center" mb={2}>
          Добавить задание на оценку
        </Typography>
        <TaskForm
          settings={settings.settings}
          submitForm={submitForm}
          loading={state.isLoading}
        />
      </>
    );
};

export default AddTask;
