import { createSlice } from "@reduxjs/toolkit";
import { servicesApi } from "./services/services";

const initialState = {
  error: null,
  filter_s: [
    ["нэи", "Свободного назначения", "", "", ""],
    ["адм_округ", "ЮАО", "", "", ""],
    ["зона", "ТТК-ЧТК", "", "", ""],
    ["метро_время", "в транспортной доступности", "", "", ""],
    ["этаж", "1 этаж", "", "", ""],
    ["окна_тип", "Есть, окна стандартного размера", "", "", ""],
  ],
  filter_r: [
    ["нэи", "Свободного назначения", "", "", ""],
    ["адм_округ", "ЮАО", "", "", ""],
    ["зона", "ТТК-ЧТК", "", "", ""],
    ["метро_время", "в транспортной доступности", "", "", ""],
    ["этаж", "1 этаж", "", "", ""],
    ["окна_тип", "Есть, окна стандартного размера", "", "", ""],
  ],
  changedFilters: false,
  task: JSON.parse(sessionStorage.getItem("task_object")) || null,
  target_s: null,
  target_r: null,
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    changeCopyFilter_s: (state, action) => {
      state.filter_r = action.payload;
      state.filter_s = action.payload;
      sessionStorage.setItem("filters_r", JSON.stringify(action.payload));
      state.changedFilters = true;
    },
    changeCopyFilter_r: (state, action) => {
      state.filter_s = action.payload;
      state.filter_r = action.payload;
      sessionStorage.setItem("filters_s", JSON.stringify(action.payload));
      state.changedFilters = true;
    },
    setFilters_s: (state, action) => {
      state.filter_s = action.payload;
      sessionStorage.setItem("filters_s", JSON.stringify(action.payload));
    },
    setFilters_r: (state, action) => {
      state.filter_r = action.payload;
      sessionStorage.setItem("filters_r", JSON.stringify(action.payload));
    },
    restoreFilters: (state) => {
      state.filter_s = JSON.parse(sessionStorage.getItem("filters_s"));
      state.filter_r = JSON.parse(sessionStorage.getItem("filters_r"));
      state.changedFilters = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        servicesApi.endpoints.oldCalc.matchFulfilled,
        (state, action) => {
          const { status, task } = action.payload;
          state.filter_s = status.filters_s;
          state.filter_r = status.filters_r;
          state.task = task;
          state.target_s = status.target_s;
          state.target_r = status.target_r;
          sessionStorage.setItem("filters_s", JSON.stringify(status.filters_s));
          sessionStorage.setItem("filters_r", JSON.stringify(status.filters_r));
          sessionStorage.setItem("task_object", JSON.stringify(task));
        }
      )
      .addMatcher(
        servicesApi.endpoints.newCalc.matchFulfilled,
        (state, action) => {
          const { status, task } = action.payload;
          state.filter_s = status.filters_s;
          state.filter_r = status.filters_r;
          state.task = task;
          state.target_s = status.target_s;
          state.target_r = status.target_r;
          state.filter_s = sessionStorage.setItem(
            "filters_s",
            JSON.stringify(status.filters_s)
          );
          state.filter_r = sessionStorage.setItem(
            "filters_r",
            JSON.stringify(status.filters_r)
          );
          sessionStorage.setItem("task_object", JSON.stringify(task));
        }
      )
      .addMatcher(
        servicesApi.endpoints.filters.matchFulfilled,
        (state, action) => {
          const { filters_r, filters_s } = action.payload;
          state.filter_s = filters_s;
          state.filter_r = filters_r;
          sessionStorage.setItem("filters_s", JSON.stringify(filters_s));
          sessionStorage.setItem("filters_r", JSON.stringify(filters_r));
          state.changedFilters = true;
        }
      );
    // .addMatcher(
    //   (action) => action.type.endsWith("rejected"),
    //   (state, action) => {
    //     state.error = action.payload.error;
    //     state.changedFilters = false
    //   }
    // );
  },
});

export const {
  setFilters_s,
  setFilters_r,
  restoreFilters,
  changeCopyFilter_s,
  changeCopyFilter_r,
} = filtersSlice.actions;

export default filtersSlice.reducer;
