import React from "react";
import { Typography } from "@mui/material";
import { AddFormSP } from "../Components/AddForm/AddFormSP";
import { useSelector } from "react-redux";
import { useAddSAnalogMutation } from "../storage/services/s_analogs";
import { checkSquare, parseNumber } from "../utils/functions";

const AddSP = () => {
  const [submitSP, state] = useAddSAnalogMutation();

  const settings = useSelector((state) => state.settings);
  const submitForm = (data) => {
    if (checkSquare(data)) {
      const newData = { ...data };
      newData["цена_продажи"] = parseNumber(newData["цена_продажи"]);
      submitSP(newData);
    } else {
      alert("Площадь должна быть больше нуля");
    }
  };

  if (settings.loading) {
    return <></>;
  }
  if (settings.error) {
    return <>Ошибка</>;
  }

  if (state.isSuccess) {
    alert("Аналог успешно добавлен " + JSON.stringify(state.data));
  }

  if (Object.keys(settings.settings).length > 0)
    return (
      <>
        <Typography variant="h5" align="center" mb={2}>
          Новый аналог для метода сравнения продаж
        </Typography>
        <AddFormSP
          settings={settings.settings}
          submitForm={submitForm}
          loading={state.isLoading}
        />
      </>
    );
};

export default AddSP;
