import React from "react";
import "./style.css";
import {
  Button,
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  Typography,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLoginMutation } from "../../storage/services/users";
import { useDispatch } from "react-redux";
import { setUser } from "../../storage/authSlice";

const AuthForm = ({ error, setError }) => {
  const [values, setValues] = React.useState({
    username: "",
    password: "",
    showPassword: false,
  });
  const [logIn, result] = useLoginMutation();
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const buttonHandler = (e) => {
    e.preventDefault();
    logIn({ username: values.username, password: values.password }).unwrap().then(res=>{
      if (res.access_token) {
        dispatch(setUser(values.username));
      }
    });
  };

  if (result.isError) {
    setError(true);
  }

  return (
    <Box
      pt={2}
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        gap: "20px",
        bgcolor: "rgba(128,128,128,0.15)",
      }}
    >
      <Box
        sx={{
          marginTop: 12,
          width: 350,
          height: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "20px",
          border: "1px solid",
          borderRadius: 5,
          bgcolor: "#fff",
        }}
      >
        <Box ml={1} sx={{ width: 296 }}>
          <Typography fontSize={24} fontFamily={"tahoma"}>
            Авторизация
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography ml={1} mb={1} variant="h6" fontFamily={"tahoma"}>
            Имя
          </Typography>
          <FormControl
            size="small"
            sx={{ m: 1, width: "35ch" }}
            variant="outlined"
          >
            <OutlinedInput
              error={error}
              id="outlined-adornment-username"
              value={values.username}
              onChange={handleChange("username")}
              inputProps={{
                "aria-label": "username",
              }}
            />
            {error && (
              <FormHelperText error>
                Неверное имя пользователя или пароль
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box>
          <Typography ml={1} mb={1} variant="h6" fontFamily={"tahoma"}>
            Пароль
          </Typography>
          <FormControl
            size="small"
            sx={{ m: 1, width: "35ch" }}
            variant="outlined"
          >
            <OutlinedInput
              error={error}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error && (
              <FormHelperText error>
                Неверное имя пользователя или пароль
              </FormHelperText>
            )}
          </FormControl>
        </Box>
        <Button color="success" variant="contained" onClick={buttonHandler}>
          Отправить
        </Button>
      </Box>
    </Box>
  );
};

export default AuthForm;
