import { apiRedux } from "./api";

export const tasksApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    addTask: build.mutation({
      query: (body) => ({
        url: "task/add_one",
        method: "POST",
        body: body,
      }),
    }),
    getTask: build.mutation({
      query: (task) => ({
        url: `task/find_one/`,
        params: { task },
      }),
    }),
    updateTask: build.mutation({
      query: (body) => ({
        url: "task/update_one",
        method: "POST",
        body: body,
      }),
    }),
    getTasks: build.query({
      query: () => ({
        url: "task/all",
      }),
    }),
    deleteTask: build.mutation({
      query: ({ task }) => ({
        url: `task/${task}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddTaskMutation,
  useDeleteTaskMutation,
  useGetTasksQuery,
  useUpdateTaskMutation,
  useGetTaskMutation,
} = tasksApi;
