import { Box, Button, Checkbox, Grid, Input, Typography } from "@mui/material";
import React from "react";
import api from "../Api";
import { Ctx } from "../App";
import MainTableInfo from "../Components/MainTableInfo/MainTableInfo";

const MainTable = () => {
  const current = new Date();
  const date = `${current.getFullYear()}-${current.getMonth()}-${current.getDate()}`;

  const { cookie } = React.useContext(Ctx);
  const [tableflag, setTableFlag] = React.useState(false);
  const [allRec, setAllRec] = React.useState({});
  const [startDate, setStartDate] = React.useState(date);
  const [endDate, setEndDate] = React.useState(date);
  const [square, setSquare] = React.useState([0, 500]);
  const [strokes, setStrokes] = React.useState({
    analogsSP: true,
    analogsDP: true,
    objectEval: true,
    square: false,
    addedAfter: false,
    addedBefore: false,
  });
  const [checked, setChecked] = React.useState({
    id: true,
    адм_округ: true,
    адрес: true,
    адрес_дата: true,
    архивный: true,
    вход_расп: true,
    вход_тип: true,
    геокоординаты: true,
    дата_предложения: true,
    зона: true,
    изменен_кем: true,
    изменен_когда: true,
    источник: true,
    кадастр_дом_дата: true,
    класс_объекта: true,
    комм_расходы: true,
    метро_время: true,
    метро_дата: true,
    метро_имя: true,
    метро_расст: true,
    ндс: true,
    нэи: true,
    объект_тип: true,
    окна_тип: true,
    округ_дата: true,
    опер_расходы: true,
    отделка: true,
    оценка_стоимости: true,
    парковка: true,
    площадь_второй: true,
    площадь_диапазон: true,
    площадь_общая: true,
    площадь_первый: true,
    площадь_подвал: true,
    площадь_цоколь: true,
    применен_к_объектам: true,
    применены_аналоги: true,
    проверка_пройдена: true,
    район_дата: true,
    санузел: true,
    скан: true,
    создан_кем: true,
    создан_когда: true,
    телефон: true,
    торг: true,
    улица: true,
    улица_дата: true,
    цена_аренды: true,
    цена_продажи: true,
    этаж: true,
  });

  const ArrCheckbox = [
    "id",
    "адм_округ",
    "адрес",
    "адрес_дата",
    "архивный",
    "вход_расп",
    "вход_тип",
    "геокоординаты",
    "дата_предложения",
    "зона",
    "изменен_кем",
    "изменен_когда",
    "источник",
    "кадастр_дом_дата",
    "класс_объекта",
    "комм_расходы",
    "метро_время",
    "метро_дата",
    "метро_имя",
    "метро_расст",
    "ндс",
    "нэи",
    "объект_тип",
    "окна_тип",
    "округ_дата",
    "опер_расходы",
    "отделка",
    "оценка_стоимости",
    "парковка",
    "площадь_второй",
    "площадь_диапазон",
    "площадь_общая",
    "площадь_первый",
    "площадь_подвал",
    "площадь_цоколь",
    "применен_к_объектам",
    "применены_аналоги",
    "проверка_пройдена",
    "район_дата",
    "санузел",
    "скан",
    "создан_кем",
    "создан_когда",
    "телефон",
    "торг",
    "улица",
    "улица_дата",
    "цена_аренды",
    "цена_продажи",
    "этаж",
  ];

  const btnOKHandler = () => {
    const lines = [
      `(${strokes["analogsSP"] ? "класс_объекта='аналог продажа'" : ""}${
        strokes["analogsSP"] && strokes["analogsDP"] ? " OR " : ""
      }${strokes["analogsDP"] ? "класс_объекта='аналог аренда'" : ""}${
        (strokes["analogsDP"] && strokes["objectEval"]) ||
        (strokes["analogsSP"] && strokes["objectEval"])
          ? " OR "
          : ""
      }${strokes["objectEval"] ? "класс_объекта='объект оценки'" : ""})`,
      strokes["square"] ? "площадь_общая>=" + square[0] : "",
      strokes["square"] ? "площадь_общая<=" + square[1] : "",
      strokes["addedAfter"] ? `создан_когда>='${startDate}'` : "",
      strokes["addedBefore"] ? `создан_когда<='${endDate}'` : "",
    ];
    const newLines = lines.filter((el) => el !== "");
    if (
      !strokes["analogsSP"] &&
      !strokes["analogsDP"] &&
      !strokes["objectEval"]
    ) {
      alert("Выберите хотя бы один класс объекта");
    } else {
      api
        .objects({
          cookie: cookie,
          filters: {
            columns: Object.keys(checked).filter((el) =>
              checked[el] ? el : ""
            ),
            lines: newLines,
          },
        })
        .then((data) => {
          if (data.flag) {
            setAllRec(data.message.reverse());
          } else alert(data.message);
          setTableFlag(true);
        });
    }
  };

  const handleChangeChecked = (e) => {
    const newChecked = { ...checked };
    newChecked[e.target.id] = e.target.checked;
    setChecked(newChecked);
  };

  const reset = () => {
    setChecked({
      id: true,
      адм_округ: false,
      адрес: false,
      адрес_дата: false,
      архивный: false,
      вход_расп: false,
      вход_тип: false,
      геокоординаты: false,
      дата_предложения: false,
      зона: false,
      изменен_кем: false,
      изменен_когда: false,
      источник: false,
      кадастр_дом_дата: false,
      класс_объекта: false,
      комм_расходы: false,
      метро_время: false,
      метро_дата: false,
      метро_имя: false,
      метро_расст: false,
      ндс: false,
      нэи: false,
      объект_тип: false,
      окна_тип: false,
      округ_дата: false,
      опер_расходы: false,
      отделка: false,
      оценка_стоимости: false,
      парковка: false,
      площадь_второй: false,
      площадь_диапазон: false,
      площадь_общая: false,
      площадь_первый: false,
      площадь_подвал: false,
      площадь_цоколь: false,
      применен_к_объектам: false,
      применены_аналоги: false,
      проверка_пройдена: false,
      район_дата: false,
      санузел: false,
      скан: false,
      создан_кем: false,
      создан_когда: false,
      телефон: false,
      торг: false,
      улица: false,
      улица_дата: false,
      цена_аренды: false,
      цена_продажи: false,
      шаблон: false,
      этаж: false,
    });
  };

  const select = () => {
    setChecked({
      id: true,
      адм_округ: true,
      адрес: true,
      адрес_дата: true,
      архивный: true,
      вход_расп: true,
      вход_тип: true,
      геокоординаты: true,
      дата_предложения: true,
      зона: true,
      изменен_кем: true,
      изменен_когда: true,
      источник: true,
      кадастр_дом_дата: true,
      класс_объекта: true,
      комм_расходы: true,
      метро_время: true,
      метро_дата: true,
      метро_имя: true,
      метро_расст: true,
      ндс: true,
      нэи: true,
      объект_тип: true,
      окна_тип: true,
      округ_дата: true,
      опер_расходы: true,
      отделка: true,
      оценка_стоимости: true,
      парковка: true,
      площадь_второй: true,
      площадь_диапазон: true,
      площадь_общая: true,
      площадь_первый: true,
      площадь_подвал: true,
      площадь_цоколь: true,
      применен_к_объектам: true,
      применены_аналоги: true,
      проверка_пройдена: true,
      район_дата: true,
      санузел: true,
      скан: true,
      создан_кем: true,
      создан_когда: true,
      телефон: true,
      торг: true,
      улица: true,
      улица_дата: true,
      цена_аренды: true,
      цена_продажи: true,
      этаж: true,
    });
  };

  const handleCheckedStrokes = (e) => {
    const newStrokes = { ...strokes };
    newStrokes[e.target.id] = e.target.checked;
    setStrokes(newStrokes);
  };

  const handleBlur = () => {
    if (square[0] < 0 || square[1] < 0) {
      setSquare([0, square[1]]);
    } else if (square[1] <= square[0]) {
      setSquare([square[0], square[0] + 10]);
    }
  };

  return (
    <>
      {tableflag ? (
        <MainTableInfo
          setTableFlag={setTableFlag}
          allRec={allRec}
          checked={checked}
        />
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "56%" }}>
            <fieldset>
              <legend>Какие отображать столбцы</legend>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {ArrCheckbox.map((el) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        width: "33%",
                        alignItems: "center",
                      }}
                      key={el + "_b"}
                    >
                      <Checkbox
                        onChange={handleChangeChecked}
                        id={el}
                        checked={checked[el]}
                        disabled={el === "id"}
                      />
                      <Typography key={el + "_t"}>{el}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </fieldset>
            <Button
              onClick={btnOKHandler}
              variant="contained"
              color="success"
              sx={{ margin: 2, width: 120 }}
            >
              OK
            </Button>
            <Button
              onClick={reset}
              variant="contained"
              color="error"
              sx={{ margin: 2, width: 120 }}
            >
              Сброс
            </Button>
            <Button
              onClick={select}
              variant="contained"
              color="secondary"
              sx={{ margin: 2, width: 150 }}
            >
              Выбрать все
            </Button>
          </Box>
          <Box sx={{ width: "44%" }}>
            <fieldset>
              <legend>Какие отображать строки</legend>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Checkbox
                  onChange={handleCheckedStrokes}
                  id="analogsSP"
                  checked={strokes["analogsSP"]}
                />
                <Typography>Аналоги СП</Typography>
                <Checkbox
                  onChange={handleCheckedStrokes}
                  checked={strokes["analogsDP"]}
                  id="analogsDP"
                />
                <Typography>Аналоги ДП</Typography>
                <Checkbox
                  onChange={handleCheckedStrokes}
                  checked={strokes["objectEval"]}
                  id="objectEval"
                />
                <Typography>Объекты оценки</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Checkbox
                  onChange={handleCheckedStrokes}
                  checked={strokes["square"]}
                  id="square"
                />
                <Typography>Общая площадь</Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography>от</Typography>
                  </Grid>
                  <Grid item>
                    <Input
                      value={square[0]}
                      onChange={(e) => setSquare([e.target.value, square[1]])}
                      size="small"
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 0,
                        type: "number",
                        "aria-labelledby": "input1-square",
                      }}
                      sx={{ maxWidth: "50px" }}
                    />
                  </Grid>
                  {/* <Grid item xs>
                            <Slider
                                getAriaLabel={() => 'Square range'}
                                value={square}
                                onChange={(e, newValue)=>setSquare(newValue)}
                                valueLabelDisplay="auto"
                                disableSwap
                                min={0}
                                max={1000}
                            />
                        </Grid> */}
                  <Grid item>
                    <Typography>до</Typography>
                  </Grid>
                  <Grid item>
                    <Input
                      value={square[1]}
                      onChange={(e) => setSquare([square[0], e.target.value])}
                      size="small"
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 0,
                        type: "number",
                        "aria-labelledby": "input2-square",
                      }}
                      sx={{ maxWidth: "50px" }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Checkbox
                  onChange={handleCheckedStrokes}
                  checked={strokes["addedAfter"]}
                  id="addedAfter"
                />
                <Typography>Добавленные после</Typography>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Checkbox
                  onChange={handleCheckedStrokes}
                  checked={strokes["addedBefore"]}
                  id="addedBefore"
                />
                <Typography>Добавленные до</Typography>
                <input
                  type="date"
                  style={{ marginLeft: "26px" }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Box>
            </fieldset>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MainTable;
