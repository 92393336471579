import { TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { requiredText } from "../utils/constants";

export const MuiTextField = ({
  name,
  label,
  sxStyle,
  rules,
  optional = false,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: optional ? false : requiredText, ...rules }}
      render={({ field: { onChange, ref, value } }) => (
        <TextField
          value={value}
          inputRef={ref}
          onChange={onChange}
          label={label}
          variant="outlined"
          error={!!errors[name]}
          helperText={errors[name]?.message}
          sx={sxStyle}
          {...props}
          autoComplete="off"
          size="small"
          inputProps={{
            "data-test": `${name}`,
          }}
        />
      )}
    />
  );
};
