import React, { useEffect } from "react";
import {
  useGetTaskMutation,
  useUpdateTaskMutation,
} from "../storage/services/tasks";
import { TaskForm } from "../Components/TaskForm/TaskForm";
import { useSelector } from "react-redux";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const EditTask = () => {
  const taskID = useSelector((state) => state.headerInfo.task);
  const [getTask, state] = useGetTaskMutation();
  const [updateTask, updateState] = useUpdateTaskMutation();
  const [id, setId] = React.useState("");
  const settings = useSelector((state) => state.settings);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setId(location.state.key);
      getTask(location.state.key);
    } else if (taskID) {
      setId(taskID);
      getTask(taskID);
    }
  }, [taskID, getTask, location.state]);

  const submitForm = (data) => {
    let sumSquare =
      parseInt(data["площадь_подвал"]) +
      parseInt(data["площадь_цоколь"]) +
      parseInt(data["площадь_первый"]) +
      parseInt(data["площадь_второй"]) +
      parseInt(data["площадь_третий"]);
    if (sumSquare > 0) {
      updateTask(data)
        .unwrap()
        .then((res) => alert("Задание успешно обновлено"))
        .catch((err) => console.log(err));
    } else {
      alert("Общая площадь должна быть больше нуля");
    }
  };

  const { data } = state;

  if (Object.keys(settings.settings).length > 0)
    return (
      <>
        <Typography variant="h5" align="center" mb={2}>
          Редактировать задание на оценку
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            value={id}
            onChange={(e) => setId(e.target.value)}
            label="Задание на оценку"
            variant="outlined"
            size="small"
            autoComplete="off"
          ></TextField>
          <Button variant="outlined" size="small" onClick={(e) => getTask(id)}>
            Получить
          </Button>
        </Box>
        <TaskForm
          settings={settings.settings}
          submitForm={submitForm}
          data={data}
          loading={updateState.isLoading}
        />
      </>
    );
};

export default EditTask;
