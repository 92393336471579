export const yesNo = ["Да", "Нет"];

export const has = ["есть", "нет"];

export const used = ["Используется", "Не используется"];

export const valuation_purpose = ["Аукцион", "Банкроство", "Изъятие"];

export const valuation_template = ["М-38", "М-159", "П-178"];

export const type_of_building = ["Жилое", "Нежилое", "Не определено"];

export const bargain = ["Предусмотрен", "Не предусмотрен"];

export const vat = ["с НДС", "УСН", "без НДС", "НДС не облагается"];

export const best_use = [
  "Офисное",
  "Торговое",
  "Свободного назначения",
  "Производственно-складское",
];

export const best_use_extend = [
  "Офисное",
  "Торговое",
  "Свободного назначения",
  "Производственно-складское",
  "Не используется",
];

export const bathroom = ["Есть", "Нет"];

export const finishing = [
  "Улучшенная отделка (Люкс)",
  "Стандартная отделка",
  "Требуется косметический ремонт",
  "Требуется капитальный ремонт",
  "Без отделки",
];

export const object_type = [
  "Нежилое встроенное помещение",
  "Нежилое отдельно стоящее здание",
];

export const parking = ["организованная", "стихийная", "без парковки"];

export const windows = [
  "Есть, витрины",
  "Есть, большие окна",
  "Есть, окна стандартного размера",
  "Есть, окна в приямках/маленькие окна",
  "Нет окон",
];

export const entr_type = ["Отдельный", "Общий"];

export const entrance = [
  "Первая линия магистральной улицы",
  "Первая линия второстепенной улицы",
  "Внутриквартально/вход со двора",
];

export const walk_time = [
  "зона прямой видимости (менее 100 м)",
  "до 5 минут пешком",
  "5-10 минут пешком",
  "10-15 минут пешком",
  "в транспортной доступности",
];

export const zone = [
  "Внутри БК",
  "БК-СК",
  "СК-ТТК",
  "ТТК-ЧТК",
  "ЧТК-МКАД",
  "за МКАД до 10 км",
  "за МКАД более 10 км",
];

export const adm_District = [
  "ЦАО",
  "САО",
  "СВАО",
  "ВАО",
  "ЮВАО",
  "ЮАО",
  "ЮЗАО",
  "ЗАО",
  "СЗАО",
  "ЗелАО",
  "НАО",
  "ТАО",
];

export const date_Offer = [
  "2023-12",
  "2023-11",
  "2023-10",
  "2023-09",
  "2023-08",
  "2023-07",
  "2023-06",
  "2023-05",
  "2023-04",
  "2023-03",
  "2023-02",
  "2023-01",
  "2022-12",
  "2022-11",
  "2022-10",
  "2022-09",
  "2022-08",
  "2022-07",
  "2022-06",
  "2022-05",
  "2022-04",
  "2022-03",
  "2022-02",
  "2022-01",
  "2021-12",
  "2021-11",
  "2021-10",
  "2021-09",
  "2021-08",
  "2021-07",
  "2021-06",
  "2021-05",
  "2021-04",
  "2021-03",
  "2021-02",
  "2021-01",
  "2020-12",
  "2020-11",
  "2020-10",
  "2020-09",
  "2020-08",
  "2020-07",
  "2020-06",
  "2020-05",
  "2020-04",
  "2020-03",
  "2020-02",
  "2020-01",
];

export const oper_exp = ["включены", "не включены"];
export const comm_exp = ["включены", "не включены"];

export const metro = [
  "Авиамоторная",
  "Автозаводская",
  "Академическая",
  "Александровский сад",
  "Алексеевская",
  "Алма-Атинская",
  "Алтуфьево",
  "Аминьевская",
  "Андроновка",
  "Аникеевка",
  "Аннино",
  "Арбатская",
  "Аэропорт",
  "Бабушкинская",
  "Багратионовская",
  "Баковка",
  "Балтийская",
  "Баррикадная",
  "Бауманская",
  "Беговая",
  "Белокаменная",
  "Беломорская",
  "Белорусская",
  "Беляево",
  "Бескудниково",
  "Бибирево",
  "Библиотека им. Ленина",
  "Битца",
  "Битцевский парк",
  "Борисово",
  "Боровицкая",
  "Боровское шоссе",
  "Ботанический сад",
  "Братиславская",
  "Бульвар Адмирала Ушакова",
  "Бульвар Дмитрия Донского",
  "Бульвар Рокоссовского",
  "Бунинская Аллея",
  "Бутово",
  "Бутырская",
  "ВДНХ",
  "Варшавская",
  "Верхние Котлы",
  "Верхние Лихоборы",
  "Владыкино",
  "Водники",
  "Водный стадион",
  "Войковская",
  "Волгоградский проспект",
  "Волжская",
  "Волоколамская",
  "Воробьёвы горы",
  "Воронцовская",
  "Выставочная",
  "Выхино",
  "Говорово",
  "Гражданская",
  "Давыдково",
  "Дегунино",
  "Деловой центр",
  "Депо",
  "Динамо",
  "Дмитровская",
  "Добрынинская",
  "Долгопрудная",
  "Домодедовская",
  "Достоевская",
  "Дубровка",
  "Жулебино",
  "ЗИЛ",
  "Зорге",
  "Зюзино",
  "Зябликово",
  "Измайлово",
  "Измайловская",
  "Каланчёвская",
  "Калитники",
  "Калужская",
  "Кантемировская",
  "Каховская",
  "Каширская",
  "Киевская",
  "Китай-город",
  "Кленовый бульвар",
  "Кожуховская",
  "Коломенская",
  "Коммунарка",
  "Комсомольская",
  "Коньково",
  "Коптево",
  "Косино",
  "Котельники",
  "Красногвардейская",
  "Красногорская",
  "Краснопресненская",
  "Красносельская",
  "Красные Ворота",
  "Красный Балтиец",
  "Красный Строитель",
  "Крестьянская застава",
  "Кропоткинская",
  "Крылатское",
  "Крымская",
  "Кузнецкий мост",
  "Кузьминки",
  "Кунцевская",
  "Курская",
  "Курьяново",
  "Кутузовская",
  "Ленинский проспект",
  "Лермонтовский проспект",
  "Лесопарковая",
  "Лефортово",
  "Лианозово",
  "Лихоборы",
  "Лобня",
  "Локомотив",
  "Ломоносовский проспект",
  "Лубянка",
  "Лужники",
  "Лухмановская",
  "Люблино",
  "Марк",
  "Марксистская",
  "Марьина Роща",
  "Марьино",
  "Маяковская",
  "Медведково",
  "Международная",
  "Менделеевская",
  "Минская",
  "Митино",
  "Мичуринский проспект",
  "Мнёвники",
  "Молодёжная",
  "Москва-Товарная",
  "Москворечье",
  "Мякинино",
  "Нагатинская",
  "Нагорная",
  "Народное Ополчение",
  "Нахабино",
  "Нахимовский проспект",
  "Некрасовка",
  "Немчиновка",
  "Нижегородская",
  "Новаторская",
  "Новогиреево",
  "Новодачная",
  "Новокосино",
  "Новокузнецкая",
  "Новопеределкино",
  "Новослободская",
  "Новохохловская",
  "Новоясеневская",
  "Новые Черёмушки",
  "Одинцово",
  "Озёрная",
  "Окружная",
  "Окская",
  "Октябрьская",
  "Октябрьское поле",
  "Ольховая",
  "Опалиха",
  "Орехово",
  "Остафьево",
  "Отрадное",
  "Охотный Ряд",
  "Павелецкая",
  "Павшино",
  "Панфиловская",
  "Парк Победы",
  "Парк культуры",
  "Партизанская",
  "Пенягино",
  "Первомайская",
  "Перерва",
  "Перово",
  "Петровский парк",
  "Петровско-Разумовская",
  "Печатники",
  "Пионерская",
  "Планерная",
  "Площадь Гагарина",
  "Площадь Ильича",
  "Площадь Революции",
  "Подольск",
  "Покровское",
  "Полежаевская",
  "Полянка",
  "Пражская",
  "Преображенская площадь",
  "Прокшино",
  "Пролетарская",
  "Проспект Вернадского",
  "Проспект Мира",
  "Профсоюзная",
  "Пушкинская",
  "Пятницкое шоссе",
  "Рабочий посёлок",
  "Раменки",
  "Рассказовка",
  "Речной вокзал",
  "Рижская",
  "Римская",
  "Ростокино",
  "Румянцево",
  "Рязанский проспект",
  "Савёловская",
  "Саларьево",
  "Свиблово",
  "Севастопольская",
  "Селигерская",
  "Семёновская",
  "Серпуховская",
  "Сетунь",
  "Силикатная",
  "Сколково",
  "Славянский бульвар",
  "Смоленская",
  "Сокол",
  "Соколиная гора",
  "Сокольники",
  "Солнцево",
  "Спартак",
  "Спортивная",
  "Сретенский бульвар",
  "Стахановская",
  "Стрешнево",
  "Строгино",
  "Студенческая",
  "Сухаревская",
  "Сходненская",
  "Таганская",
  "Тверская",
  "Театральная",
  "Текстильщики",
  "Терехово",
  "Тестовская",
  "Технопарк",
  "Тимирязевская",
  "Третьяковская",
  "Трикотажная",
  "Тропарёво",
  "Трубная",
  "Тульская",
  "Тургеневская",
  "Тушинская",
  "Тёплый Стан",
  "Угрешская",
  "Улица 1905 года",
  "Улица Академика Янгеля",
  "Улица Горчакова",
  "Улица Дмитриевского",
  "Улица Скобелевская",
  "Улица Старокачаловская",
  "Университет",
  "Филатов Луг",
  "Фили",
  "Филёвский парк",
  "Фонвизинская",
  "Фрунзенская",
  "Хлебниково",
  "Ховрино",
  "Хорошёво",
  "Хорошёвская",
  "ЦСКА",
  "Царицыно",
  "Цветной бульвар",
  "Черкизовская",
  "Чертановская",
  "Чеховская",
  "Чистые пруды",
  "Чкаловская",
  "Шаболовская",
  "Шелепиха",
  "Шереметьевская",
  "Шипиловская",
  "Шоссе Энтузиастов",
  "Щербинка",
  "Щукинская",
  "Щёлковская",
  "Электрозаводская",
  "Юго-Восточная",
  "Юго-Западная",
  "Южная",
  "Ясенево",
];
