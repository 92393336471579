import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  objId: 0,
};

export const objectSlice = createSlice({
  name: "object",
  initialState,
  reducers: {
    setObjId: (state, action) => {
      if (localStorage.getItem("objId")) {
        state.objId = localStorage.getItem("objId");
      } else {
        state.objId = action.payload || "";
      }
    },
    test: () => {
      console.log(123)
    }
  },
});

export const { setObjId, test } = objectSlice.actions;

export default objectSlice.reducer;
