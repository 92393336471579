import { transpose } from "../../utils/functions";
import { apiRedux } from "./api";

export const servicesApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    settings: build.query({
      query: () => ({
        url: "service/settings",
      }),
    }),
    newCalc: build.mutation({
      query: (task) => ({
        url: `service/new_calc`,
        params: { task },
      }),
      transformResponse: (response) => {
        return {
          analogs_s: transpose(response.columns.sp),
          analogs_r: transpose(response.columns.dp),
          short_s: transpose(response.short.sp),
          short_r: transpose(response.short.dp),
          saved_id: response.saved_id,
          ...response,
        };
      },
    }),
    oldCalc: build.mutation({
      query: (id) => ({
        url: "service/old_calc",
        params: { id },
      }),
      transformResponse: (response) => {
        return {
          analogs_s: transpose(response.columns.sp),
          analogs_r: transpose(response.columns.dp),
          short_s: transpose(response.short.sp),
          short_r: transpose(response.short.dp),
          saved_id: response.saved_id,
          ...response,
        };
      },
    }),
    updateCalc: build.mutation({
      query: (body) => ({
        url: `service/update_calc`,
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        return {
          analogs_s: transpose(response.columns.sp),
          analogs_r: transpose(response.columns.dp),
          short_s: transpose(response.short.sp),
          short_r: transpose(response.short.dp),
          ...response,
        };
      },
    }),
    filters: build.mutation({
      query: (body) => ({
        url: `service/filters`,
        method: "POST",
        body,
      }),
    }),
    export: build.mutation({
      query: (task) => ({
        url: `service/export`,
        method: "POST",
        params: { task },
      }),
    }),
    text: build.query({
      query: () => ({
        url: `service/text`,
      }),
    }),
    same_tasks: build.mutation({
      query: (task) => ({
        url: `service/same_tasks`,
        params: { task },
      }),
    }),
    map_sales: build.mutation({
      query: (task) => ({
        url: `service/map_sales`,
        params: { task },
      }),
    }),
    map_rents: build.mutation({
      query: (task) => ({
        url: `service/map_rents`,
        params: { task },
      }),
    }),
    filtered_price: build.mutation({
      query: (body) => ({
        url: `service/filtered_price`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useNewCalcMutation,
  useOldCalcMutation,
  useUpdateCalcMutation,
  useFiltersMutation,
  useExportMutation,
  useTextQuery,
  useSame_tasksMutation,
  useMap_salesMutation,
  useMap_rentsMutation,
  useFiltered_priceMutation,
} = servicesApi;

export const {
  endpoints: { newCalc, updateCalc, oldCalc, filters },
} = servicesApi;
