import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export default function MuiAutoComplete({
  name,
  label,
  settings,
  sxStyle,
  customOptions = false,
  freeSolo = false,
  ...rest
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      {...rest}
      render={({ field }) => (
        <Autocomplete
          freeSolo={freeSolo}
          {...field}
          value={field.value || null}
          onChange={(_, data) => field.onChange(data)}
          onInputChange={
            freeSolo ? (_, data) => field.onChange(data) : () => {}
          }
          options={customOptions ? settings : settings[label]}
          sx={sxStyle}
          renderInput={(params) => {
            return (
              <TextField
                className="test"
                inputRef={field.ref}
                {...params}
                label={label}
                error={!!errors[name]}
                helperText={errors[name]?.message}
                size="small"
                inputProps={{
                  ...params.inputProps,
                  "data-ac": true,
                  "data-test": `${name}`,
                }}
              />
            );
          }}
        />
      )}
    />
  );
}
