import { Typography } from "@mui/material";
import React from "react";

const Develop = () => {
  return (
    <Typography variant="h4" textAlign={"center"}>
      В разработке
    </Typography>
  );
};

export default Develop;
