import { apiRedux } from "./api";

export const savedApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    getSaved: build.mutation({
      query: (task) => ({
        url: `saved/find_all`,
        params: { task },
      }),
    }),
    addSaved: build.mutation({
      query: ({ task, comment }) => {
        return {
          url: `saved/add_one`,
          method: "GET",
          params: { task, msg: comment },
        };
      },
    }),
    deleteSaved: build.mutation({
      query: (id) => ({
        url: `saved/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddSavedMutation,
  useDeleteSavedMutation,
  useGetSavedMutation,
} = savedApi;
