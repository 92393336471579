import { apiRedux } from "./api";

export const usersApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation({
      query: (credentials) => ({
        url: "user/register",
        method: "POST",
        body: credentials,
      }),
    }),
    login: build.mutation({
      query: (credentials) => ({
        url: "user/login",
        method: "POST",
        body: credentials,
      }),
    }),
    getUsers: build.query({
      query: () => ({
        url: "user/find_all",
      }),
    }),
    deleteUser: build.mutation({
      query: ({ id }) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLoginMutation,
  useRegisterMutation,
  useDeleteUserMutation,
} = usersApi;

export const {
  endpoints: { login },
} = usersApi;
