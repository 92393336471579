import React from "react";
import "./App.css";
import AuthForm from "./Components/AuthForm";
import Menu from "./Components/Menu";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import MainTable from "./pages/MainTable";
import { Header } from "./Components/Header/Header";
import LogsPage from "./pages/LogsPage";
import UsersPage from "./pages/UsersPage";
import ResultsPage from "./pages/ResultsPage";
import { useGetSettingsQuery } from "./storage/services/api";
import AddSP from "./pages/AddSP";
import AddDP from "./pages/AddDP";
import EditAnalogSP from "./pages/EditAnalogSP";
import EditAnalogDP from "./pages/EditAnalogDP";
import AddTask from "./pages/AddTask";
import EditTask from "./pages/EditTask";
import CalcResult from "./pages/CalcResult";
import Develop from "./pages/Develop";
import { useSelector } from "react-redux";

export const Ctx = React.createContext();

function App() {
  const ls = localStorage;
  const ss = sessionStorage;

  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(
    ss.getItem("open") ? ss.getItem("open") === "true" : true
  );
  useGetSettingsQuery();

  const cookie = useSelector((state) => state.auth.token);

  return (
    <Ctx.Provider value={{ ls, ss, open }}>
      <Header open={open} setOpen={setOpen} />
      {cookie ? "" : <AuthForm error={error} setError={setError} />}
      {cookie && <Menu open={open} />}
      <div
        className="App"
        style={open ? { marginLeft: "280px" } : { marginLeft: 0 }}
      >
        <Routes>
          <Route path="/" element={cookie && <Main />} />
          <Route path="/addAnalogSP" element={cookie && <AddSP />} />
          <Route
            path="/EditAnalogSP"
            element={cookie && <EditAnalogSP clss={"аналог продажа"} />}
          />
          <Route
            path="/EditAnalogDP"
            element={cookie && <EditAnalogDP clss={"аналог аренда"} />}
          />
          <Route path="/addAnalogDP" element={cookie && <AddDP />} />
          <Route path="/MainTable" element={cookie && <MainTable />} />
          <Route path="/AddObject" element={cookie && <AddTask />} />
          <Route path="/EditObject" element={cookie && <EditTask />} />
          <Route path="/logs" element={cookie && <LogsPage />} />
          <Route path="/users" element={cookie && <UsersPage />} />
          <Route path="/results" element={cookie && <ResultsPage />} />
          <Route path="/Calc" element={cookie && <CalcResult />} />
          <Route path="/dev" element={cookie && <Develop />} />
        </Routes>
      </div>
    </Ctx.Provider>
  );
}

export default App;
