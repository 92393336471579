import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import "dayjs/locale/ru";
import { Controller, useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const DateComponent = ({ name, label, sxStyle, optional = false }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: optional ? false : "Необходимо заполнить",
      }}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DatePicker
            inputRef={ref}
            value={value}
            onChange={(event) => {
              onChange(dayjs(event).format("YYYY-MM-DD"));
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  sx={sxStyle}
                  error={!!error}
                  helperText={error?.message}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    "data-test": `${name}`,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: label === "Дата оценки" &&
                      dayjs(value).isBefore("2024-01-01") && (
                        <InputAdornment position="start">
                          <Tooltip title="Оценка на дату ранее 01.01.2024 может быть невозможна из-за недостатка архивных аналогов">
                            <IconButton>
                              <ErrorOutlineIcon sx={{ fill: "#ff9800" }} />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                  }}
                />
              );
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};
