import { apiRedux } from "./api";

export const sAnalogsApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    getSAnalogs: build.query({
      query: () => ({
        url: "s_analog/find_all",
      }),
    }),
    getSAnalog: build.mutation({
      query: ({ id, task }) => ({
        url: `s_analog/find_one/`,
        params: { id, task },
      }),
    }),
    addSAnalog: build.mutation({
      query: (body) => ({
        url: `s_analog/add_one`,
        method: "POST",
        body: body,
      }),
    }),
    updateSAnalog: build.mutation({
      query: ({ data, s_id }) => ({
        url: `s_analog/update_one?id=${s_id}`,
        method: "POST",
        body: data,
      }),
    }),
    updateSAnalogSaved: build.mutation({
      query: ({ data, s_id, saved_id }) => ({
        url: `s_analog/update_one_saved?id=${s_id}&saved_id=${saved_id}`,
        method: "POST",
        body: data,
      }),
    }),
    deleteSAnalog: build.mutation({
      query: ({ s_id }) => ({
        url: `s_analog/${s_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddSAnalogMutation,
  useDeleteSAnalogMutation,
  useGetSAnalogMutation,
  useGetSAnalogsQuery,
  useUpdateSAnalogMutation,
  useUpdateSAnalogSavedMutation,
} = sAnalogsApi;
