import { apiRedux } from "./api";

export const resultsApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    findAllResults: build.query({
      query: () => ({
        url: "task/show_all",
      }),
    }),
    findOneResult: build.mutation({
      query: (task) => ({
        url: `result/find_one/${task}`,
      }),
    }),
    addResult: build.mutation({
      query: (body) => ({
        url: "result/add_one",
        method: "POST",
        body: body,
      }),
    }),
    updateResult: build.mutation({
      query: (body) => ({
        url: "result/update_one",
        method: "POST",
        body: body,
      }),
    }),
    deleteResult: build.mutation({
      query: (task) => ({
        url: `result/${task}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddResultMutation,
  useDeleteResultMutation,
  useFindAllResultsQuery,
  useFindOneResultMutation,
  useUpdateResultMutation,
} = resultsApi;
