import React from 'react';
import {ListItem, ListItemButton, ListItemText} from '@mui/material';
import { Link } from 'react-router-dom';

const MenuButton = ({ title, color, path }) => {
  return (
    <Link to={path}>
        <ListItem disablePadding>
            <ListItemButton sx={{bgcolor: color}}>
                <ListItemText primary={title}/>
            </ListItemButton>
        </ListItem>
    </Link>
  )
}

export default MenuButton;