import { TableCell } from "@mui/material";
import React from "react";
import { formatNumber } from "../../utils/functions";
import { TableCellStyle } from "../../utils/params";

export const CellWithBgcolor = ({ cell }) => {
  const bgcolor = "rgba(128, 128, 128, 0.3)";
  return (
    <TableCell
      sx={{
        ...TableCellStyle,
        bgcolor: bgcolor,
      }}
    >
      {formatNumber(cell)}
    </TableCell>
  );
};
