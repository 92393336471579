import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PATH,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().auth.token || sessionStorage.getItem("cookie");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiRedux = createApi({
  baseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    getSettings: build.query({
      query: () => ({
        url: "service/settings",
      }),
    }),
  }),
});

// export const enhancedApi = api.enhanceEndpoints({
//   endpoints: () => ({
//     getPost: () => "test",
//   }),
// });

export const { useGetSettingsQuery } = apiRedux;

export const {
  endpoints: { getSettings },
} = apiRedux;
