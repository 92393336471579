import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Reorder } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCopyFilter_r,
  changeCopyFilter_s,
  restoreFilters,
  setFilters_r,
  setFilters_s,
} from "../../storage/filtersSlice";
import {
  useFiltered_priceMutation,
  useFiltersMutation,
} from "../../storage/services/services";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const opt = [
  "нэи",
  "адм_округ",
  "зона",
  "площадь_диапазон",
  "метро_время",
  "этаж",
  "окна_тип",
  "торг",
  "вход_расп",
  "парковка",
  "объект_тип",
  "отделка",
  "санузел",
  "ндс",
  "",
];

const targetOpt = ["min", "max"];

const gridStyle = {
  display: "grid",
  gridTemplateColumns: " 24px 1fr 1fr 1fr 1fr 10px 1px",
  gap: 5,
  alignItems: "center",
  mb: 2,
  width: "95%",
};

const Filters = ({
  setupParams,
  openFilters,
  checked,
  setOpenFilters,
  getOldCalc,
  target,
  setTarget,
}) => {
  const settings = useSelector((state) => state.settings);
  const { filter_s, filter_r, changedFilters, task } = useSelector(
    (state) => state.filters
  );
  const saved_id = useSelector((state) => state.setup.params.saved_id);

  const [currFilt, setCurrFilt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [fetchFilters] = useFiltersMutation();
  const [fetchPrice] = useFiltered_priceMutation();

  useEffect(() => {
    if (
      sessionStorage.getItem("filters_s") &&
      sessionStorage.getItem("filters_r")
    ) {
      dispatch(restoreFilters());
    } else {
      setCurrFilt(checked ? filter_r : filter_s);
      sessionStorage.setItem("filters_s", JSON.stringify(filter_s));
      sessionStorage.setItem("filters_r", JSON.stringify(filter_r));
    }
  }, [dispatch]);

  useEffect(() => {
    if (changedFilters) {
      if (filter_r !== void 0 && filter_s !== void 0) {
        setCurrFilt(checked ? filter_r : filter_s);
      }
    }
  }, [filter_r, filter_s]);

  const handleApplyFilters = () => {
    if (checked) {
      dispatch(setFilters_r(currFilt));
      fetchFilters({
        saved_id: setupParams.params.saved_id,
        filters_s: filter_s,
        filters_r: currFilt,
      })
        .unwrap()
        .then((data) => {
          if (data.success) {
            getOldCalc(data.saved_id);
          } else {
            console.log(data);
          }
        });
    } else {
      dispatch(setFilters_s(currFilt));
      fetchFilters({
        saved_id: setupParams.params.saved_id,
        filters_s: currFilt,
        filters_r: filter_r,
      })
        .unwrap()
        .then((data) => {
          if (data.success) {
            getOldCalc(data.saved_id);
          } else {
            alert(data.detail);
          }
        });
    }
  };
  const deleteRowFilters = (i) => {
    let newCurrFilt = [...currFilt];
    newCurrFilt.splice(i, 1);
    setCurrFilt(newCurrFilt);
  };

  const deterValue = (availableValues) => {
    if (availableValues[0] === "площадь_диапазон") {
      const opt = deterOptions(availableValues[0]);
      for (let i = 0; i < opt.length; i++) {
        switch (true) {
          case i === 0 && task["площадь_общая"] < opt[i].match(/\d+/)[0]:
            return deterOptions(availableValues[0])[i];
          case i === opt.length - 1 &&
            task["площадь_общая"] > opt[i].match(/\d+/)[0]:
            return deterOptions(availableValues[0])[i];
          case task["площадь_общая"] >= opt[i].match(/\d+/)[0] &&
            task["площадь_общая"] <= opt[i + 1].match(/\d+/)[0]:
            return deterOptions(availableValues[0])[i];
          default:
            continue;
        }
      }
    } else {
      if (task[availableValues[0]]) {
        return task[availableValues[0]];
      } else {
        return deterOptions(availableValues[0])[0];
      }
    }
  };

  const addRowFilters = () => {
    const availableValues = opt.filter(
      (num) => !currFilt.map((row) => row[0]).includes(num)
    );
    const newFilt = [...currFilt];
    newFilt.push([availableValues[0], deterValue(availableValues), "", "", ""]);
    setCurrFilt(newFilt);
  };

  const deterOptions = (value) => {
    switch (value) {
      case "нэи":
        return settings.settings["Наиболее эфф. использ."];
      case "адм_округ":
        return settings.settings["Административный округ"];
      case "зона":
        return settings.settings["Кольцевая зона"];
      case "площадь_диапазон":
        if (
          settings.settings["Площадь_диапазон_ДП"] !== undefined &&
          settings.settings["Площадь_диапазон_СП"] !== undefined
        ) {
          if (checked) {
            return settings.settings["Площадь_диапазон_ДП"];
          } else return settings.settings["Площадь_диапазон_СП"];
        } else return [];
      case "метро_время":
        return settings.settings["Время от метро"];
      case "этаж":
        return settings.settings["Этаж"];
      case "окна_тип":
        return settings.settings["Наличие и размер окон"];
      case "торг":
        return settings.settings["Торг"];
      case "вход_расп":
        return settings.settings["Расположение входа"];
      case "парковка":
        return settings.settings["Парковка"];
      case "объект_тип":
        return settings.settings["Тип объекта"];
      case "отделка":
        return settings.settings["Состояние отделки"];
      case "санузел":
        return settings.settings["Наличие санузла"];
      case "ндс":
        return settings.settings["НДС"];
      case "тип_здания_аналог":
        return settings.settings["Тип здания аналог"];
      default:
        return [""];
    }
  };

  const handleChange = (e, newValue, i, j) => {
    const newFilt = JSON.parse(JSON.stringify(currFilt));
    newFilt[i][j] = newValue;
    if (j === 0) {
      if (task[newFilt[i][0]]) {
        newFilt[i][1] = task[newFilt[i][0]];
      } else {
        newFilt[i][1] = deterOptions(newFilt[i][0])[0];
      }
    }
    setCurrFilt(newFilt);
  };

  const disableOption = (option, i) => {
    let options = "";
    currFilt.map((row, j) => {
      if (option === currFilt[j][0] && option !== currFilt[i][0]) {
        options += `option === '${option}' || `;
      }
      return options;
    });
    return eval(options.slice(0, -4));
  };

  const handleCopy = () => {
    if (checked) {
      dispatch(changeCopyFilter_r(currFilt));
    } else {
      dispatch(changeCopyFilter_s(currFilt));
    }
  };
  if (!currFilt) {
    console.log(currFilt);
    return <></>;
  }

  const sortHandler = () => {
    if (target) {
      setIsLoading(true);
      fetchPrice({
        saved_id: saved_id,
        deal_type: checked ? "rent" : "sale",
        target: target,
      })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setIsLoading(false);
            getOldCalc(data.saved_id);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      alert("Заполните поле сортировка");
    }
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={openFilters} fullWidth maxWidth="100vw">
        <DialogContent>
          <Box sx={{ ...gridStyle, marginLeft: "40px" }}>
            <Typography></Typography>
            <Typography>
              <b>Параметр</b>
            </Typography>
            <Typography>
              <b>Основное значение</b>
            </Typography>
            <Typography>
              <b>Добавочное 1</b>
            </Typography>
            <Typography>
              <b>Добавочное 2</b>
            </Typography>
          </Box>
          <Reorder.Group
            as="ol"
            axis="y"
            values={currFilt}
            onReorder={setCurrFilt}
            style={{ listStyleType: "none" }}
          >
            {currFilt.map((row, i) => {
              return (
                <Reorder.Item key={row} value={row}>
                  <Box
                    key={i}
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <Box sx={gridStyle}>
                      <DragIndicatorIcon />
                      <Autocomplete
                        onChange={(e, newValue) =>
                          handleChange(e, newValue, i, 0)
                        }
                        getOptionDisabled={(option) => disableOption(option, i)}
                        value={currFilt[i][0]}
                        disablePortal
                        options={opt}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                        sx={{ minWidth: "150px" }}
                      />
                      <Autocomplete
                        onChange={(e, newValue) =>
                          handleChange(e, newValue, i, 1)
                        }
                        value={
                          currFilt[i][1] === "None" ? "100-300" : currFilt[i][1]
                        }
                        disablePortal
                        options={deterOptions(currFilt[i][0])}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                        sx={{ minWidth: "150px", marginLeft: 1 }}
                      />
                      <Autocomplete
                        onChange={(e, newValue) =>
                          handleChange(e, newValue, i, 2)
                        }
                        value={currFilt[i][2]}
                        disablePortal
                        options={deterOptions(currFilt[i][0])}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                        sx={{ minWidth: "150px", marginLeft: 1 }}
                      />
                      <Autocomplete
                        onChange={(e, newValue) =>
                          handleChange(e, newValue, i, 3)
                        }
                        value={currFilt[i][3]}
                        disablePortal
                        options={deterOptions(currFilt[i][0])}
                        renderInput={(params) => (
                          <TextField {...params} label="" />
                        )}
                        sx={{ minWidth: "150px", marginLeft: 1 }}
                      />
                      <Typography sx={{ alignSelf: "center", marginLeft: 2 }}>
                        {currFilt[i][4]}
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <IconButton
                          color="inherit"
                          onClick={() => deleteRowFilters(i)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Reorder.Item>
              );
            })}
          </Reorder.Group>
          {currFilt.length < 9 && (
            <Box sx={{ marginLeft: "32px" }}>
              <IconButton color="inherit" onClick={addRowFilters}>
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              width: "100%",
              ml: 7,
              mr: 3,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Autocomplete
                freeSolo
                options={targetOpt}
                value={target}
                onChange={(e, newValue) => setTarget(newValue)}
                onInputChange={(e) => setTarget(e?.target.value)}
                size="small"
                sx={{ width: "150px" }}
                renderInput={(params) => (
                  <TextField {...params} label="Ориентир" />
                )}
              />
              <Button variant="contained" size="small" onClick={sortHandler}>
                Применить
              </Button>
              <Typography alignSelf={"center"}>
                {Math.ceil(
                  (currFilt[currFilt.length - 1]?.slice(-1)[0] / 100) * 1.5
                )}{" "}
                с
              </Typography>
              <Tooltip
                title={"Примерное время ожидания запроса"}
                placement="top"
              >
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={() => setOpenFilters(false)}
                variant="contained"
                color="secondary"
              >
                Закрыть
              </Button>
              <Button variant="contained" onClick={handleCopy}>
                Скопировать фильтры {checked ? "ДП в СП" : "СП в ДП"}
              </Button>
              <Button
                onClick={() => {
                  handleApplyFilters(currFilt);
                }}
                variant="contained"
              >
                Применить
              </Button>
            </Box>
          </Box>
        </DialogActions>
        {/* <Box sx={{ display: "flex", p: 2, gap: 2, flexWrap: "wrap" }}>
        {checked
          ? data.messages_r.split("\n").map((message, i) => (
              <Typography key={i} sx={{ width: i === 0 ? "100%" : "32%" }}>
                {message}
              </Typography>
            ))
          : data.messages_s.split("\n").map((message, i) => (
              <Typography key={i} sx={{ width: i === 0 ? "100%" : "32%" }}>
                {message}
              </Typography>
            ))}
      </Box> */}
      </Dialog>
    </Box>
  );
};

export default Filters;
