import {
  Box,
  Button,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { formatNumber } from "../../utils/functions";
import { useDispatch } from "react-redux";
import { changeWeight } from "../../storage/setupSlice";
import debounce from "lodash.debounce";

export const CalcFooter = ({
  checked,
  setupParams,
  setOpenSave,
  setOpenResult,
  exportHandler,
}) => {
  const { setup, params } = setupParams;

  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(params.weight_s);

  const sliderDebounce = debounce(
    (newValue) => dispatch(changeWeight(newValue)),
    500
  );

  const handleSlider = useCallback((newValue) => {
    setSliderValue(newValue);
    sliderDebounce(newValue);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "12px",
        flex: "0 0 auto",
      }}
    >
      {Object.keys(setup.results).map((key, i) => {
        return (
          <TextField
            key={i}
            value={formatNumber(setup.results[key])}
            label={key}
            size="small"
          />
        );
      })}
      {!checked ? (
        <Box sx={{ width: "15%", margin: "0 20px" }}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider
              aria-label="Weight"
              value={sliderValue}
              onChange={(e, newValue) => handleSlider(newValue)}
              step={0.05}
              min={0}
              max={1}
            />
            <Typography minWidth={"32px"}>{sliderValue}</Typography>
          </Stack>
        </Box>
      ) : (
        <></>
      )}
      <Button
        onClick={() => setOpenSave(true)}
        variant="contained"
        size="small"
      >
        Сохранить
      </Button>
      <Button
        onClick={exportHandler}
        variant="contained"
        size="small"
        sx={{ ml: 1 }}
      >
        Экспорт
      </Button>
      <Button
        onClick={() => setOpenResult(true)}
        variant="contained"
        size="small"
        sx={{ ml: 1 }}
      >
        Результат
      </Button>
    </Box>
  );
};
