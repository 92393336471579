import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";
import { MySVG } from "../MySVG";
import { useNavigate } from "react-router-dom";
import { TableCellStyle } from "../../utils/params";
import { formatNumber, redirect } from "../../utils/functions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrderPinned,
  changePinned,
  changeSq_method,
  changeWg_method,
} from "../../storage/setupSlice";
import { CellWithBgcolor } from "../Cells/CellWithBgcolor";
import { Reorder } from "framer-motion";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export const CalcTable = ({
  setupParams,
  openChangeOrder,
  setOpenChangeOrder,
}) => {
  const { title_eng, setup } = setupParams;
  const { wg_method, sq_method_s, sq_method_r, saved_id } = useSelector(
    (state) => state.setup.params
  );
  const checked = useSelector((state) => state.setup.checked);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [skip, setSkip] = useState(true);
  const [tempPinned, setTempPinned] = useState(setup.pinned);

  useEffect(() => {
    setTempPinned(setup.pinned);
  }, [setup.pinned]);

  const correctHandler = () => {
    dispatch(changeWg_method());
  };

  const handleSQ = (event) => {
    dispatch(changeSq_method(event.target.value));
  };

  const handlePinned = (e, value) => {
    dispatch(changePinned(value));
  };

  const pinStyleHandle = (value) => {
    if (setup.pinned.includes(value)) {
      return { height: "15px", color: "green" };
    } else return { height: "22px", color: "black" };
  };

  const findPinned = () => {
    return setup.pinned
      .map((n) => setup.analogs[0].indexOf(n))
      .filter((id) => id !== -1);
  };

  const data = skip ? setup.short : setup.analogs;

  const handleChangeOrder = () => {
    dispatch(changeOrderPinned(tempPinned));
    setOpenChangeOrder(false);
  };

  return (
    <>
      <Dialog
        open={openChangeOrder}
        onClose={() => setOpenChangeOrder(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Изменение порядка закрепленных</DialogTitle>
        <DialogContent sx={{ overflowX: "scroll" }}>
          <Reorder.Group
            values={tempPinned}
            onReorder={setTempPinned}
            as="ol"
            axis="x"
            style={{
              listStyleType: "none",
              display: "flex",
              paddingLeft: 0,
            }}
          >
            {tempPinned.length === 0 ? (
              <Typography>Сначала закрепите аналоги </Typography>
            ) : (
              <></>
            )}
            {tempPinned.map((pin, i) => (
              <Reorder.Item key={pin} value={pin}>
                <Typography sx={{ textAlign: "center", mb: 1 }}>{i}</Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <DragIndicatorIcon />
                  <Paper sx={{ p: 1 }}>
                    <Typography>{pin}</Typography>
                  </Paper>
                </Box>
              </Reorder.Item>
            ))}
          </Reorder.Group>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChangeOrder(false)}>Отменить</Button>
          <Button onClick={handleChangeOrder} variant="contained">
            Применить
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer
        sx={{ flex: "1 0 auto", maxHeight: "calc(100vh - 211px)", pb: 2 }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            {data.slice(0, 1).map((row, i) => {
              return (
                <TableRow key={i}>
                  {row.map((cell, j) => {
                    switch (j) {
                      case 0:
                        return (
                          <TableCell key={j} sx={{ paddingLeft: 0 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <IconButton onClick={() => setSkip(!skip)}>
                                {skip ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <KeyboardArrowUpIcon />
                                )}
                              </IconButton>
                              <b>{cell}</b>
                            </Box>
                          </TableCell>
                        );
                      case 1:
                        return (
                          <TableCell key={j} sx={{ minWidth: "154px" }}>
                            <b>{cell}</b>
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell key={j}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {data[data.length - 1][j] ? (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    navigate(`/EditAnalog${title_eng}`, {
                                      state: { cell, saved_id },
                                    })
                                  }
                                >
                                  <CheckIcon
                                    fontSize="small"
                                    sx={{ fill: "green" }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    navigate(`/EditAnalog${title_eng}`, {
                                      state: { cell, saved_id },
                                    })
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              )}
                              <b>{cell}</b>
                              {
                                <IconButton
                                  onClick={(e) => handlePinned(e, cell)}
                                >
                                  <MySVG props={pinStyleHandle(cell)} />
                                </IconButton>
                              }
                            </Box>
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {data.slice(1, -1).map((row, i) => {
              return (
                <TableRow key={i}>
                  {row.map((cell, j) => {
                    switch (true) {
                      case cell.toString().indexOf("http") >= 0:
                        return (
                          <TableCell
                            key={j}
                            sx={{
                              ...TableCellStyle,
                              bgcolor: findPinned().includes(j)
                                ? "rgba(0,128,0, 0.2)"
                                : "",
                            }}
                          >
                            <Button
                              onClick={(e) => redirect(e, cell)}
                              sx={{ textTransform: "none" }}
                            >
                              Ссылка
                            </Button>
                          </TableCell>
                        );
                      case cell
                        .toString()
                        .includes("Корректирровка по матрице АБН"):
                        return (
                          <TableCell
                            key={j}
                            sx={{
                              ...TableCellStyle,
                              bgcolor: findPinned().includes(j)
                                ? "rgba(0,128,0, 0.2)"
                                : "",
                            }}
                          >
                            {formatNumber(cell)}
                            <Radio
                              checked={
                                checked
                                  ? sq_method_r === "1"
                                  : sq_method_s === "1"
                              }
                              onChange={handleSQ}
                              value="1"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "1" }}
                            />
                          </TableCell>
                        );
                      case cell
                        .toString()
                        .includes("Коэффициент по графику АБН"):
                        return (
                          <TableCell
                            key={j}
                            sx={{
                              ...TableCellStyle,
                              bgcolor: findPinned().includes(j)
                                ? "rgba(0,128,0, 0.2)"
                                : "",
                            }}
                          >
                            {formatNumber(cell)}
                            <Radio
                              checked={
                                checked
                                  ? sq_method_r === "2"
                                  : sq_method_s === "2"
                              }
                              onChange={handleSQ}
                              value="2"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "2" }}
                            />
                          </TableCell>
                        );
                      case row[0].includes("РАСЧЕТ КОРРЕКТИРОВОК"):
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "НДС" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Торг" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Зона расположения" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Удаленность от метро" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Диапазон площади, кв.м" &&
                        i > 33 &&
                        (checked ? sq_method_r === "1" : sq_method_s === "1"):
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Площадь, кв.м" &&
                        i > 33 &&
                        (checked ? sq_method_r === "2" : sq_method_s === "2"):
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Этаж" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Тип входа" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Наличие окон и их размер" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Наличие парковки" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Тип объекта" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Состояние отделки" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Наличие санузла" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Расположение входа" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Эксплуатационные расходы" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Коммунальные расходы" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Тип площади" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Высота потолков, м" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case row[0] === "Время продажи" && i > 33:
                        return <CellWithBgcolor key={j} cell={cell} />;
                      case typeof cell === "number":
                        return (
                          <TableCell
                            key={j}
                            sx={{
                              ...TableCellStyle,
                              bgcolor: findPinned().includes(j)
                                ? "rgba(0,128,0, 0.2)"
                                : "",
                            }}
                          >
                            {formatNumber(cell)}
                          </TableCell>
                        );
                      case !cell && cell !== 0:
                        return (
                          <TableCell
                            key={j}
                            sx={{
                              ...TableCellStyle,
                              bgcolor: findPinned().includes(j)
                                ? "rgba(0,128,0, 0.2)"
                                : "",
                            }}
                          ></TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            key={j}
                            sx={{
                              bgcolor: findPinned().includes(j)
                                ? "rgba(0,128,0, 0.2)"
                                : "",
                            }}
                          >
                            {cell}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>Взвешивание</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Button
                    onClick={correctHandler}
                    variant="outlined"
                    size="small"
                  >
                    {wg_method}
                  </Button>
                  <Tooltip
                    title="
                   1 - по количеству корректировок
                   2 - по отклонению от начальной цены
                   3 - среднее арифметическое
                   "
                  >
                    <IconButton size="small">
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
