import { isRejectedWithValue } from "@reduxjs/toolkit";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.meta.arg.endpointName !== "login") {
      alert(JSON.stringify(action.payload.data));
    }
  }

  return next(action);
};
