import {
  Autocomplete,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React from "react";
import api from "../Api";
import { Ctx } from "../App";
import { Table } from "../Components/Table/Table";
import { handleAutocomlete } from "../utils/functions";

const LogsPage = () => {
  const { cookie } = React.useContext(Ctx);
  const [logs, setLogs] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({
    username: "Все пользователи",
    datetime: " ",
    type: "Все",
    errors: false,
    actions: false,
    requests: false,
  });
  const [users, setUsers] = React.useState(["night@bot", "nightwatch.bot"]);

  React.useEffect(() => {
    api.users({ cookie: cookie }).then((data) => {
      if (data.flag) {
        let tmpArr = data.message.map((el) => el.username);
        setUsers((u) => tmpArr.concat(u));
      } else alert(data.message);
    });
  }, [cookie]);

  const handleBtn = () => {
    api
      .find_logs({
        cookie: cookie,
        filters: {
          username:
            filters.username === "Все пользователи" ? "" : filters.username,
          datetime: filters.datetime === " " ? "" : filters.datetime,
          errors: filters.errors,
          actions: filters.actions,
          requests: filters.requests,
        },
      })
      .then((data) => {
        if (data.flag) {
          setLogs(data.message);
          setHeaders(data.headers);
          setLoading(true);
        } else alert(data.message);
      });
  };

  const selectHandlerDate = (e) => {
    const newSelect = { ...filters };
    newSelect["datetime"] = e.target.value;
    setFilters(newSelect);
  };

  const selectHandlerTypes = (e) => {
    const newSelect = { ...filters };
    newSelect["type"] = e.target.value;
    switch (e.target.value) {
      case "actions":
        newSelect["actions"] = true;
        newSelect["errors"] = false;
        newSelect["requests"] = false;
        break;
      case "errors":
        newSelect["actions"] = false;
        newSelect["errors"] = true;
        newSelect["requests"] = false;
        break;
      case "requests":
        newSelect["actions"] = false;
        newSelect["errors"] = false;
        newSelect["requests"] = true;
        break;
      default:
        newSelect["actions"] = false;
        newSelect["errors"] = false;
        newSelect["requests"] = false;
    }
    setFilters(newSelect);
    console.log(newSelect);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", gap: 2, margin: "16px 0", alignItems: "center" }}
      >
        <Autocomplete
          value={filters.username}
          onChange={(e, newValue) =>
            handleAutocomlete(e, newValue, filters, setFilters)
          }
          id="username"
          options={users}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Пользователь" />
          )}
        />
        <FormControl>
          <InputLabel id="datetime-label">Период</InputLabel>
          <Select
            onChange={selectHandlerDate}
            value={filters.datetime}
            id="datetime"
            labelId="datetime-label"
            label="Период"
            sx={{ width: 150 }}
          >
            <MenuItem value={" "}>За все время</MenuItem>
            <MenuItem value={"1d"}>1 день</MenuItem>
            <MenuItem value={"2d"}>2 дня</MenuItem>
            <MenuItem value={"1w"}>Неделя</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="type-label">Тип</InputLabel>
          <Select
            onChange={selectHandlerTypes}
            value={filters.type}
            id="type"
            labelId="type-label"
            label="Тип"
            sx={{ width: 150 }}
          >
            <MenuItem value={"Все"}>Все типы</MenuItem>
            <MenuItem value={"actions"}>Действия</MenuItem>
            <MenuItem value={"errors"}>Ошибки</MenuItem>
            <MenuItem value={"requests"}>Запросы</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handleBtn} variant="contained" sx={{ height: "40px" }}>
          Применить
        </Button>
      </Box>
      {loading && <Table data={logs} headers={headers} />}
    </>
  );
};

export default LogsPage;
