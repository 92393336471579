import React from 'react';
import "./style.css";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box } from '@mui/material';
import { TableCellStyle } from '../../utils/params';
import { numFormat } from '../../utils/functions';

const MainTableInfo = ({ setTableFlag, checked, allRec }) =>{

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {Object.keys(checked).map((el,i)=>{
                                if(checked[el]){
                                    return <TableCell key={i+'_thead'} style={{fontWeight: 'bold'}}>{el}</TableCell>
                                } else return ''
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allRec.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((el,i)=> {
                            return (
                            <TableRow key={i+'_tr'}>
                                {el.slice(1).map((key,i)=>{
                                    if (i === 0){
                                        return <TableCell key={i+'_td'} sx={TableCellStyle}>{key}</TableCell>
                                    } else {
                                    return <TableCell key={i+'_td'} sx={TableCellStyle}>{!isNaN(key)&&key !== null ? numFormat.format(key) : key}</TableCell>
                                    }
                                })}
                            </TableRow>
                            )
                        })                          
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{maxWidth: '450px'}}>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100, 250]}
                component="div"
                count={allRec.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            </Box>
            <Button variant='contained' color='secondary' sx={{marginTop: 3}} onClick={()=>setTableFlag(false)}>Закрыть таблицу</Button>
            </>
  )
}

export default MainTableInfo