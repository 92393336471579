import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeRange_flag,
  changeRange_max,
  changeRange_min,
} from "../../storage/setupSlice";
import { calcCtx } from "../../Context/context";

export const Range = ({ setupParams, open, setOpen }) => {
  const { updateRes } = useContext(calcCtx);
  const dispatch = useDispatch();
  const [rangeMin, setRangeMin] = useState(setupParams.setup.range_min);
  const [rangeMax, setRangeMax] = useState(setupParams.setup.range_max);
  const [rangeFlag, setRangeFlag] = useState(setupParams.setup.range_flag);
  const [error, setError] = useState("");

  useEffect(() => {
    if (updateRes.error?.data?.detail) {
      setError(updateRes.error.data.detail);
    } else {
      setError("");
    }
  }, [updateRes]);

  const confirmhandler = () => {
    console.log(rangeMax);
    console.log(Number(rangeMin) > Number(rangeMax));
    switch (true) {
      case !rangeMax:
        setError("Максимальное значение не должно быть пустым");
        break;
      case Number(rangeMin) < 0:
        setError("Минимальное значение должно быть больше нуля");
        break;
      case Number(rangeMin) > Number(rangeMax):
        setError("Минимальное значение должно быть меньше максимального");
        break;

      default:
        dispatch(changeRange_min(rangeMin));
        dispatch(changeRange_max(rangeMax));
        dispatch(changeRange_flag(rangeFlag));
        setError("");
        break;
    }
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Использовать диапазон</DialogTitle>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          padding: 4,
          gridColumn: "span 5",
          alignItems: "center",
          justifyContent: "center",
          // width: "400px",
        }}
      >
        <Tooltip title="Использовать диапазон">
          <Checkbox
            checked={rangeFlag}
            onChange={(e) => setRangeFlag((prev) => !prev)}
          />
        </Tooltip>
        <TextField
          value={rangeMin}
          onChange={(e) => setRangeMin(e.target.value)}
          sx={{ width: "100px" }}
          size="small"
          label="min"
          autoComplete="off"
        />
        <TextField
          value={rangeMax}
          onChange={(e) => setRangeMax(e.target.value)}
          sx={{ width: "100px" }}
          size="small"
          label="max"
          autoComplete="off"
        />
      </Box>
      <Box sx={{ boxSizing: "border-box", pl: 4, pr: 4 }}>
        <Typography align={"center"} sx={{ color: "#f00" }}>
          {error}
        </Typography>
      </Box>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Закрыть</Button>
        <Button onClick={confirmhandler} variant="contained">
          Применить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
