import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAddSavedMutation,
  useGetSavedMutation,
} from "../storage/services/saved";
import { CalcCard } from "../Components/CalcCard/CalcCard";
import {
  useNewCalcMutation,
  useOldCalcMutation,
  useSame_tasksMutation,
  useUpdateCalcMutation,
} from "../storage/services/services";
import { CalcTableHeader } from "../Components/CalcTableHeader/CalcTableHeader";
import { CalcTable } from "../Components/CalcTable/CalcTable";
import { CalcFooter } from "../Components/CalcFooter/CalcFooter";
import { useDispatch, useSelector } from "react-redux";
import { setTaskHeader } from "../storage/headerInfoSlice";
import { resetSetup } from "../storage/setupSlice";
import Filters from "../Components/Filters/Filters";
import { dateProps } from "../utils/params";
import {
  useAddResultMutation,
  useUpdateResultMutation,
} from "../storage/services/results";
import dayjs from "dayjs";
import { calcCtx } from "../Context/context";

const CalcResult = () => {
  const { setup_s, setup_r, params, checked, changed } = useSelector(
    (state) => state.setup
  );
  const filters = useSelector((state) => state.filters);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const [openChangeObject, setOpenChangeObject] = useState(false);
  const [openChangeCalc, setOpenChangeCalc] = useState(false);
  const [task, setTask] = useState(sessionStorage.getItem("task") || "");
  const [showTable, setShowTable] = useState(false);
  const [openChangeOrder, setOpenChangeOrder] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [_, setOpenExport] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [openUpdateResult, setOpenUpdateResult] = useState(false);
  const [comment, setComment] = useState("");
  const [num, setNum] = useState("");
  const [date, setDate] = useState(" ");
  const [appraiser, setAppraiser] = useState("");
  const [openSameObj, setOpenSameObj] = useState(false);
  const [openRange, setOpenRange] = useState(false);
  const [updateRes, setUpdateRes] = useState({});
  const [target, setTarget] = useState("");
  const [savedID, setSavedID] = useState(null);

  const navigate = useNavigate();
  const [getSaved, resultSaved] = useGetSavedMutation();
  const [getNewCalc] = useNewCalcMutation();
  const [getOldCalc] = useOldCalcMutation();
  const [updateCalc, updateState] = useUpdateCalcMutation();
  const [addResult] = useAddResultMutation();
  const [addSave] = useAddSavedMutation();
  const [updateResult] = useUpdateResultMutation();
  const [getSameObj, sameObjState] = useSame_tasksMutation();

  const setupParams_S = {
    map_path: "sales",
    setup: setup_s,
    title: "СП",
    title_eng: "SP",
    params,
    table: "s_table",
  };
  const setupParams_R = {
    map_path: "rents",
    setup: setup_r,
    title: "ДП",
    title_eng: "DP",
    params,
    table: "r_table",
  };

  const { isError, data, error } = resultSaved;

  useEffect(() => {
    if (filters.task?.["номер_отчета"]) {
      setNum(filters.task["номер_отчета"]);
    }
    if (filters.task?.["дата_отчета"]) {
      setDate(filters.task?.["дата_отчета"]);
    }
    if (filters.task?.["оценщик"]) {
      setAppraiser(filters.task["оценщик"]);
    }
  }, [filters.task]);

  useEffect(() => {
    if (sessionStorage.getItem("task")) {
      if (sessionStorage.getItem("savedID")) {
        handleChangeCalc(
          _,
          sessionStorage.getItem("savedID"),
          sessionStorage.getItem("task")
        );
      } else {
        setTask(sessionStorage.getItem("task"));
        newCalcHandler(sessionStorage.getItem("task"));
      }
    } else {
      setOpenChangeObject(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (changed && task) {
      updateCalc({
        task,
        analogs_s: setup_s?.analogs[0]?.slice(2),
        analogs_r: setup_r?.analogs[0]?.slice(2),
        sorted_s: setup_s.sorted,
        sorted_r: setup_r.sorted,
        pinned_s: setup_s.pinned,
        pinned_r: setup_r.pinned,
        range_flag_s: setup_s.range_flag,
        range_min_s: Number(setup_s.range_min),
        range_max_s: Number(setup_s.range_max),
        range_flag_r: setup_r.range_flag,
        range_min_r: Number(setup_r.range_min),
        range_max_r: Number(setup_r.range_max),
        weight_s: params.weight_s,
        sq_method_s: Number(params.sq_method_s),
        sq_method_r: Number(params.sq_method_r),
        wg_method: params.wg_method,
        params_calc: {},
      }).then((res) => {
        setUpdateRes(res);
        if (res.data) {
          setOpenRange(false);
        }
      });
    }
  }, [setup_s, setup_r, task, params]);

  const handleChangeCalc = (e, id) => {
    getOldCalc(id)
      .unwrap()
      .then((res) => {
        setSavedID(id);
        sessionStorage.setItem("savedID", id);
        setShowTable(true);
        setOpenChangeCalc(false);
      })
      .catch((reason) => {
        setOpenChangeCalc(false);
        setOpenChangeObject(true);
      });
  };

  const newCalcHandler = (task) => {
    getNewCalc(task)
      .unwrap()
      .then((res) => {
        setShowTable(true);
        setOpenChangeCalc(false);
      })
      .catch((reason) => {
        setOpenChangeCalc(false);
        setOpenChangeObject(true);
      });
  };

  const changeObject = () => {
    setOpenChangeObject(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      if (task) {
        setOpenChangeObject(false);
      } else {
        alert("Необходимо задание на оценку для продолжения");
      }
    } else {
      dispatch(resetSetup());
      sessionStorage.removeItem("params");
      sessionStorage.removeItem("setup_s");
      sessionStorage.removeItem("setup_r");
      sessionStorage.removeItem("filters_s");
      sessionStorage.removeItem("filters_r");
      setShowTable(false);
      getSaved(task).then((res) => {
        if (res.data) {
          setOpenChangeObject(false);
          setOpenChangeCalc(true);
          dispatch(setTaskHeader(task));
        }
      });
    }
  };

  const saveHandler = () => {
    addSave({ task, setup_s, setup_r, params, comment, filters })
      .unwrap()
      .then((res) => {
        setOpenSave(false);
        alert("Результат успешно сохранился");
      })
      .catch((error) => {
        console.log(error);
        setOpenSave(false);
      });
  };

  const resultHandler = () => {
    if (num === "") {
      alert("Заполните номер отчета");
    } else if (date === " ") {
      alert("Заполните дату отчета");
    } else {
      addResult({
        task,
        номер_отчета: num,
        дата_отчета: date,
        comment: comment,
        оценщик: appraiser,
      })
        .unwrap()
        .then((res) => {
          setOpenResult(false);
          alert("Результат успешно сохранился");
        })
        .catch((error) => {
          if (error.status === 409) {
            setOpenResult(false);
            setOpenUpdateResult(true);
          } else {
            console.log(error);
          }
        });
    }
  };

  const updateResultHandler = () => {
    updateResult({
      task,
      номер_отчета: num,
      дата_отчета: date,
      comment: comment,
      оценщик: appraiser,
    })
      .unwrap()
      .then((res) => {
        setOpenUpdateResult(false);
      });
  };

  const exportHandler = () => {
    fetch(`${process.env.REACT_APP_PATH}service/export?task=${task}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.blob())
      .then((data) => {
        let link = document.createElement("a");
        link.download = `${task}_${dayjs().format("DD.MM.YY")}.xlsm`;
        link.href = URL.createObjectURL(data);
        link.click();
        URL.revokeObjectURL(link.href);
      });
  };

  const sameMaphandler = () => {
    fetch(`${process.env.REACT_APP_PATH}service/map_same_task?task=${task}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.text())
      .then((html) => {
        window.open(
          `${process.env.REACT_APP_PATH}service/map_same_task?task=${task}`
        );
      });
  };

  const sameObjHandler = () => {
    getSameObj(task).then((data) => setOpenSameObj(true));
  };

  return (
    <calcCtx.Provider value={{ updateState, updateRes }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updateState.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showTable && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          <CalcTableHeader
            setupParams={checked ? setupParams_R : setupParams_S}
            checked={checked}
            changeObject={changeObject}
            setOpenChangeOrder={setOpenChangeOrder}
            setOpenFilters={setOpenFilters}
            sameObjHandler={sameObjHandler}
            openRange={openRange}
            setOpenRange={setOpenRange}
          />
          <CalcTable
            setupParams={checked ? setupParams_R : setupParams_S}
            openChangeOrder={openChangeOrder}
            setOpenChangeOrder={setOpenChangeOrder}
          />
          <CalcFooter
            checked={checked}
            setupParams={checked ? setupParams_R : setupParams_S}
            setOpenSave={setOpenSave}
            setOpenExport={setOpenExport}
            setOpenResult={setOpenResult}
            exportHandler={exportHandler}
          />
        </Box>
      )}
      {openChangeCalc && (
        <Dialog open={openChangeCalc}>
          <DialogTitle>Выбор сохраненного расчета</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                p: 1,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {data.map((variant, i) => {
                return (
                  <Box
                    key={i}
                    onClick={(e) => handleChangeCalc(e, variant.id, task)}
                  >
                    <CalcCard
                      id={variant.id}
                      username={variant.username}
                      date={variant["date_when_saved"]}
                      comment={variant.comment}
                    />
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpenChangeCalc(false);
                setOpenChangeObject(true);
              }}
            >
              Назад
            </Button>
            <Button variant="contained" onClick={() => newCalcHandler(task)}>
              Новый расчет
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={openChangeObject} onClose={handleClose}>
        <DialogTitle>{"Выбор задания для расчета"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Введите номер задания на оценку</DialogContentText>
          <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
            <TextField
              autoFocus
              margin="dense"
              value={task}
              onChange={(e) => {
                setTask(e.target.value);
              }}
              label="Задание на оценку"
              id="task"
              error={isError}
              helperText={error?.data?.detail}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (task) {
                setOpenChangeObject(false);
              } else {
                navigate(-1);
              }
            }}
          >
            Назад
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Выбрать
          </Button>
        </DialogActions>
      </Dialog>
      {openFilters && (
        <Filters
          openFilters={openFilters}
          checked={checked}
          setupParams={checked ? setupParams_R : setupParams_S}
          setOpenFilters={setOpenFilters}
          getOldCalc={getOldCalc}
          target={target}
          setTarget={setTarget}
        />
      )}
      <Dialog open={openSave} onClose={() => setOpenSave(false)}>
        <DialogTitle>Заполните для сохранения</DialogTitle>
        <DialogContent>
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label="Комментарий"
            multiline
            sx={{ mt: 1, width: "400px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenSave(false)}>
            Назад
          </Button>
          <Button variant="contained" onClick={saveHandler}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openResult} onClose={() => setOpenResult(false)}>
        <DialogTitle>Заполните для результата</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            onChange={(e) => setNum(e.target.value)}
            value={num}
            margin="dense"
            id="номер_отчета"
            label="Номер отчета"
            fullWidth
            autoComplete="off"
          />
          <TextField
            onChange={(e) => setDate(e.target.value)}
            value={date}
            margin="dense"
            id="дата_отчета"
            label="Дата отчета"
            fullWidth
            type={"date"}
            inputProps={dateProps}
            autoComplete="off"
          />
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label="Комментарий"
            multiline
            fullWidth
            margin="dense"
            autoComplete="off"
          />
          <DialogActions>
            <Button onClick={() => setOpenResult(false)} variant="contained">
              Назад
            </Button>
            <Button onClick={resultHandler} variant="contained">
              Сохранить результат
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUpdateResult}
        onClose={() => setOpenUpdateResult(false)}
      >
        <DialogTitle>Обновить результат?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenUpdateResult(false)}
              variant="contained"
            >
              НЕТ
            </Button>
            <Button onClick={updateResultHandler} variant="contained">
              ДА
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {sameObjState.isSuccess && (
        <Dialog onClose={() => setOpenSameObj(false)} open={openSameObj}>
          <DialogContent sx={{ padding: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{Object.keys(sameObjState.data)[0]}:</Typography>
              <Typography>
                <b>{Object.values(sameObjState.data)[0]}</b>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{Object.keys(sameObjState.data)[1]}:</Typography>
              <Typography>
                <b>{Object.values(sameObjState.data)[1]}</b>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenSameObj(false)}
              variant="contained"
              color="secondary"
            >
              Закрыть
            </Button>
            <Button onClick={sameMaphandler} variant="contained">
              Карта
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </calcCtx.Provider>
  );
};

export default CalcResult;
