import React from "react";
import { Table } from "../Components/Table/Table";
import { useFindAllResultsQuery } from "../storage/services/results";
import { Typography } from "@mui/material";

const ResultsPage = () => {
  const { data, isSuccess, isError, isLoading } = useFindAllResultsQuery();

  if (isSuccess) {
    if (data.length > 0) {
      return <Table data={data} headers={Object.keys(data[0])} />;
    } else {
      return <Typography>У вас пока нет сохраненных результатов</Typography>;
    }
  }

  if (isLoading) {
    return <Typography>Загрузка</Typography>;
  }

  if (isError) {
    return <Typography>Ошибка</Typography>;
  }
};

export default ResultsPage;
