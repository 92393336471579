import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { FormDP } from "../Components/FormDP/FormDP";
import { useAddRAnalogMutation } from "../storage/services/r_analogs";
import { checkSquare, parseNumber } from "../utils/functions";

const AddDP = () => {
  const settings = useSelector((state) => state.settings);
  const [submitDP, state] = useAddRAnalogMutation();
  const submitForm = (data) => {
    if (checkSquare(data)) {
      const newData = { ...data };
      newData["цена_аренды"] = parseNumber(newData["цена_аренды"]);
      submitDP(newData);
    } else {
      alert("Площадь должна быть больше нуля");
    }
  };

  if (settings.loading) {
    return <></>;
  }
  if (settings.error) {
    return <>Ошибка</>;
  }

  if (state.isSuccess) {
    alert("Аналог успешно добавлен " + JSON.stringify(state.data));
  }

  if (Object.keys(settings.settings).length > 0)
    return (
      <>
        <Typography variant="h5" align="center" mb={2}>
          Новый аналог для доходного метода
        </Typography>
        <FormDP
          settings={settings.settings}
          submitForm={submitForm}
          loading={state.isLoading}
        />
      </>
    );
};

export default AddDP;
