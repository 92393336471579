import { createSlice } from "@reduxjs/toolkit";
import { usersApi } from "./services/users";

const initialState = {
  user: sessionStorage.getItem("username"),
  token: sessionStorage.getItem("cookie"),
  isAuthenticated: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      sessionStorage.clear();
      state.token = null
      state.user = null
      state.isAuthenticated = false
    },
    setUser: (state, action) => {
      state.user = action.payload;
      sessionStorage.setItem("username", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(usersApi.endpoints.login.matchPending, (state, action) => {})
      .addMatcher(usersApi.endpoints.login.matchFulfilled, (state, action) => {
        const { access_token } = action.payload;
        state.token = access_token;
        sessionStorage.setItem("cookie", access_token);
      })
      .addMatcher(
        usersApi.endpoints.login.matchRejected,
        (state, action) => {}
      );
  },
});

export const { logout, setUser } = slice.actions;
export default slice.reducer;

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
