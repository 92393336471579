import { Paper, Typography } from "@mui/material";
import React, { useState } from "react";

export const CalcCard = ({
  id = 1,
  username = 123,
  date = "07.07.23",
  comment = "Lorem ipsum dolor sit amet.",
}) => {
  const [elevation, setElevation] = useState(2);
  return (
    <Paper
      sx={{ p: 2, boxSizing: "border-box", width: "400px", cursor: "pointer" }}
      elevation={elevation}
      onMouseEnter={() => setElevation(6)}
      onMouseLeave={() => setElevation(2)}
    >
      <Typography>Кто: {username}</Typography>
      <Typography>Когда: {date}</Typography>
      <Typography>Комментарий: {comment}</Typography>
    </Paper>
  );
};
