import React from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const FormHeader = ({ disabled }) => {
  const {
    formState: { errors },
    control,
    getValues,
  } = useFormContext();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Controller
        control={control}
        name="источник"
        rules={{
          required: "Необходимо заполнить",
          pattern: {
            value: /cian.ru/,
            message: "Добавить можно только аналог c cian.ru",
          },
        }}
        render={({ field: { onChange, ref, value } }) => (
          <TextField
            value={value}
            inputRef={ref}
            error={!!errors["источник"]}
            onChange={onChange}
            onClick={() => {
              if (disabled) {
                window.open(getValues("источник"));
              }
            }}
            label="Источник"
            variant="outlined"
            helperText={errors["источник"]?.message}
            autoComplete="off"
            sx={{ width: "100%", cursor: "pointer" }}
            inputProps={{ readOnly: disabled }}
            size="small"
            InputProps={{
              endAdornment: disabled ? (
                <InputAdornment position="end">
                  <TouchAppIcon />
                </InputAdornment>
              ) : (
                <></>
              ),
              sx: { cursor: "pointer" },
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="адрес"
        rules={{
          required: "Необходимо заполнить",
        }}
        render={({ field: { onChange, ref, value } }) => (
          <TextField
            value={value}
            inputRef={ref}
            error={!!errors["адрес"]}
            onChange={onChange}
            name="адрес"
            label="Адрес"
            variant="outlined"
            disabled={disabled}
            helperText={errors["адрес"]?.message}
            autoComplete="off"
            size="small"
          />
        )}
      />
      <Controller
        control={control}
        name="геокоординаты"
        rules={{
          required: "Необходимо заполнить",
          pattern: {
            value: disabled ? "" : /^-?\d{1,2}\.?\d+?, ?-?\d{1,2}\.?\d+?$/,
            message: "Пример 55.755831, 37.617673",
          },
        }}
        render={({ field: { onChange, ref, value } }) => (
          <TextField
            value={value}
            inputRef={ref}
            error={!!errors["геокоординаты"]}
            onChange={onChange}
            name="геокоординаты"
            label="Координаты"
            variant="outlined"
            disabled={disabled}
            helperText={errors["геокоординаты"]?.message}
            autoComplete="off"
            size="small"
          />
        )}
      />
    </Box>
  );
};
export default FormHeader;
