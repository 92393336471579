import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  IconButton,
  Menu,
  Chip,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSort, setChecked } from "../../storage/setupSlice";
import { Range } from "../Range/Range";
import SettingsIcon from "@mui/icons-material/Settings";

const MaterialUISwitch = styled(Switch)(() => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    marginTop: 6,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(34px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8796A5",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#1976d2",
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#8796A5",
    borderRadius: 20 / 2,
  },
}));

export const CalcTableHeader = ({
  setupParams,
  checked,
  changeObject,
  setOpenChangeOrder,
  setOpenFilters,
  sameObjHandler,
  openRange,
  setOpenRange,
}) => {
  const { setup, title, map_path } = setupParams;
  const dispatch = useDispatch();
  const task = useSelector((state) => state.headerInfo.task);
  const token = useSelector((state) => state.auth.token);
  const target_s = useSelector((state) => state.filters.target_s);
  const target_r = useSelector((state) => state.filters.target_r);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSelect = (e) => {
    dispatch(changeSort(e.target.value));
  };

  const handleChangeSwitch = (event) => {
    dispatch(setChecked(event.target.checked));
  };

  const handleMap = () => {
    fetch(`${process.env.REACT_APP_PATH}service/map_${map_path}?task=${task}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.text())
      .then((html) => {
        window.open(
          `${process.env.REACT_APP_PATH}service/map_${map_path}?task=${task}`
        );
      });
  };

  const getPoint = () => {
    if (checked) {
      return target_r === null ? "Нет" : target_r;
    } else {
      return target_s === null ? "Нет" : target_s;
    }
  };

  return (
    <>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            gap: "36px",
            margin: "8px 4px",
            alignItems: "center",
          }}
        >
          <Button
            onClick={changeObject}
            sx={{ fontWeight: 600, minWidth: "100px", whiteSpace: "nowrap" }}
          >
            Выбор задания
          </Button>
          <Button onClick={() => setOpenFilters(true)} sx={{ fontWeight: 600 }}>
            Фильтры
          </Button>
          <Chip label={`Ориентир: ${getPoint()}`} />
          <FormControl>
            <InputLabel id="select-label">Сортировка</InputLabel>
            <Select
              value={setup.sorted}
              labelId="select-label"
              label="Сортировка"
              onChange={handleChangeSelect}
              size="small"
              sx={{ width: "275px" }}
            >
              <MenuItem value={"null"}>По умолчанию</MenuItem>
              <MenuItem value={"price"}>По цене</MenuItem>
              <MenuItem value={"ncorr"}>По количеству корректировок</MenuItem>
              <MenuItem value={"delta"}>По разлету</MenuItem>
              <MenuItem value={"dist"}>По расстоянию</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={() => setOpenChangeOrder(true)}
            sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
          >
            Порядок прикрепленных
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
          <Typography>СП</Typography>
          <MaterialUISwitch checked={checked} onChange={handleChangeSwitch} />
          <Typography>ДП</Typography>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ ml: 2 }}
          >
            <SettingsIcon />
          </IconButton>
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Button
                  sx={{ fontWeight: 600 }}
                  onClick={() => sameObjHandler()}
                >
                  Ориентиры
                </Button>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Button
                  sx={{ fontWeight: 600, minWidth: "90px" }}
                  onClick={handleMap}
                >
                  {"Карта " + title}
                </Button>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Button
                  onClick={() => setOpenRange(true)}
                  sx={{ fontWeight: 600 }}
                >
                  Диапазон
                </Button>
              </MenuItem>
            </Menu>
          </div>
        </Box>
      </Box>
      <Divider />
      <Range
        setupParams={setupParams}
        open={openRange}
        setOpen={setOpenRange}
      />
    </>
  );
};
