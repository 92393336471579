import React from "react";
import { Table } from "../Components/Table/Table";
import { useGetUsersQuery } from "../storage/services/users";

const UsersPage = () => {
  const { data } = useGetUsersQuery();

  return <>{data && <Table data={data} />}</>;
};

export default UsersPage;
