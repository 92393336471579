import { CssBaseline, Tab, Tabs, Box, Typography } from "@mui/material";
import React from "react";
import { Ctx } from "../App";
import PropTypes from "prop-types";
import { useTextQuery } from "../storage/services/services";

const Main = () => {
  const { cookie } = React.useContext(Ctx);
  const [page, setPage] = React.useState({});
  const { data } = useTextQuery();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  React.useEffect(() => {
    if (data) {
      setPage(data);
    }
  }, [cookie, data]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CssBaseline>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="О ПРОГРАММЕ" {...a11yProps(0)} />
            <Tab label="НАЧАЛО РАБОТЫ" {...a11yProps(1)} />
            <Tab label="ОКНО ФИЛЬТРОВ" {...a11yProps(2)} />
            <Tab label="ОКНО ПАРАМЕТРОВ РАСЧЕТА СТОИМОСТИ" {...a11yProps(3)} />
            <Tab label="ОКНО РАСЧЕТА СТОИМОСТИ" {...a11yProps(4)} />
            <Tab label="СОХРАНЕНИЕ РЕЗУЛЬТАТА" {...a11yProps(5)} />
            <Tab label="СЕРВИСНЫЕ ТАБЛИЦЫ" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div
            dangerouslySetInnerHTML={{ __html: page["p00_welcome.md"] }}
          ></div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            dangerouslySetInnerHTML={{ __html: page["p01_analog.md"] }}
          ></div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div
            dangerouslySetInnerHTML={{ __html: page["p02_filters.md"] }}
          ></div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div dangerouslySetInnerHTML={{ __html: page["p03_.md"] }}></div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div dangerouslySetInnerHTML={{ __html: page["p04_calc.md"] }}></div>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <div dangerouslySetInnerHTML={{ __html: page["p05_save.md"] }}></div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div
            dangerouslySetInnerHTML={{ __html: page["p06_service.md"] }}
          ></div>
        </TabPanel>
      </Box>
    </CssBaseline>
  );
};

export default Main;
