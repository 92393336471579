import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import BasicTable from "@mui/material/Table";
import React from "react";
import "./style.css";
import { TableCellStyle } from "../../utils/params";

export const Table = ({ data, headers }) => {
  return (
    <TableContainer sx={{ maxHeight: `calc(100vh - 80px)` }}>
      <BasicTable stickyHeader>
        <TableHead>
          <TableRow>
            {headers?.map((el) => {
              return (
                <TableCell key={el}>
                  <b>{el}</b>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((el, i) => {
            return (
              <TableRow key={i}>
                {Object.values(el).map((val, i) => {
                  return (
                    <TableCell key={i} sx={TableCellStyle}>
                      {val}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </BasicTable>
    </TableContainer>
  );
};
