import { createSlice } from "@reduxjs/toolkit";
import { servicesApi } from "./services/services";

const initialState = {
  template: sessionStorage.getItem("template") || "",
  task: sessionStorage.getItem("task") || "",
};

export const headerInfoSlice = createSlice({
  name: "headerInfo",
  initialState,
  reducers: {
    resetHeaderInfo: (state) => {
      state.task = "";
      state.template = "";
    },
    setTaskHeader: (state, action) => {
      if (action.payload) {
        state.task = action.payload;
        sessionStorage.setItem("task", action.payload);
      } else {
        if (sessionStorage.getItem("task")) {
          state.task = sessionStorage.getItem("task");
        } else {
          state.task = action.payload || "";
        }
      }
    },
    setTemplate: (state, action) => {
      if (action.payload) {
        state.template = action.payload;
        sessionStorage.setItem("template", action.payload);
      } else {
        if (sessionStorage.getItem("template")) {
          state.template = sessionStorage.getItem("template");
        } else {
          state.template = action.payload || "";
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(
      servicesApi.endpoints.oldCalc.matchFulfilled,
      (state, action) => {
        const { status } = action.payload;
        state.template = status.template;
        sessionStorage.setItem("template", status.template);
      }
    )
    .addMatcher(
      servicesApi.endpoints.newCalc.matchFulfilled,
      (state, action) => {
        const { status } = action.payload;
        state.template = status.template;
        sessionStorage.setItem("template", status.template);
      }
    )
  }
});

export const { setTaskHeader, setTemplate, resetHeaderInfo } =
  headerInfoSlice.actions;

export default headerInfoSlice.reducer;
