import React from "react";
import { styled } from "@mui/material/styles";
import { Divider, List, Box, Drawer, Typography } from "@mui/material";
import MenuButton from "./MenuButton";
import { useLocation } from "react-router-dom";

const Menu = ({ children, open }) => {
  const drawerWidth = 280;
  const location = useLocation();
  const color = "#ff9218";

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      // padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          zIndex: 1099,
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div style={{ marginTop: "64px" }}>
          <Divider />
          <List>
            <Typography
              align="left"
              ml={2}
              mb={2}
              sx={{ color: "rgba(80,80,80,0.67)" }}
            >
              АНАЛОГ СП
            </Typography>
            <MenuButton
              title={"Добавить аналог СП"}
              path="/addAnalogSP"
              color={location.pathname === "/addAnalogSP" ? color : ""}
            />
            <MenuButton
              title={"Редактировать аналог"}
              path="/EditAnalogSP"
              color={location.pathname === "/EditAnalogSP" ? color : ""}
            />
          </List>
          <Divider />
          <List>
            <Typography
              align="left"
              ml={2}
              mb={2}
              sx={{ color: "rgba(80,80,80,0.67)" }}
            >
              АНАЛОГ ДП
            </Typography>
            <MenuButton
              title={"Добавить аналог ДП"}
              path="/addAnalogDP"
              color={location.pathname === "/addAnalogDP" ? color : ""}
            />
            <MenuButton
              title={"Редактировать аналог"}
              path="/EditAnalogDP"
              color={location.pathname === "/EditAnalogDP" ? color : ""}
            />
          </List>
          <Divider />
          <List>
            <Typography
              align="left"
              ml={2}
              mb={2}
              sx={{ color: "rgba(80,80,80,0.67)" }}
            >
              Задание на оценку
            </Typography>
            <MenuButton
              title={"Добавить задание на оценку"}
              path="/AddObject"
              color={location.pathname === "/AddObject" ? color : ""}
            />
            <MenuButton
              title={"Редактировать задание"}
              path="/EditObject"
              color={location.pathname === "/EditObject" ? color : ""}
            />
            <MenuButton
              title={"РАСЧЕТ СТОИМОСТИ"}
              path="/Calc"
              color={location.pathname === "/Calc" ? color : ""}
            />
          </List>
          <Divider />
          <List>
            <Typography
              align="left"
              ml={2}
              mb={2}
              sx={{ color: "rgba(80,80,80,0.67)" }}
            >
              СТАТИСТИКА
            </Typography>
            {/* <MenuButton
              title={"Показать таблицу логов"}
              path="/dev"
              color={location.pathname === "/logs" ? color : ""}
            /> */}
            {/* <MenuButton
              title={"Показать таблицу пользователей"}
              path="/dev"
              color={location.pathname === "/users" ? color : ""}
            /> */}
            <MenuButton
              title={"Показать таблицу результатов"}
              path="/results"
              color={location.pathname === "/results" ? color : ""}
            />
            {/* <MenuButton title = {'ОСНОВНАЯ ТАБЛИЦА'} path = '/mainTable' color = {location.pathname === '/mainTable' ? color : ''}/> */}
            {/* <MenuButton title={"Аналитика по таблицам"} path="/dev" /> */}
          </List>
          <Divider />
        </div>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

export default Menu;
