import { createSlice } from "@reduxjs/toolkit";
import { apiRedux } from "./services/api";

const initialState = {
  settings: {},
  error: null,
  loading: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiRedux.endpoints.getSettings.matchPending,
        (state, action) => {
          state.error = null;
          state.loading = true;
        }
      )
      .addMatcher(
        apiRedux.endpoints.getSettings.matchFulfilled,
        (state, action) => {
          state.settings = action.payload;
          sessionStorage.setItem("settings", JSON.stringify(action.payload));
          state.loading = false;
        }
      )
      .addMatcher(
        apiRedux.endpoints.getSettings.matchRejected,
        (state, action) => {
          state.error = action.payload?.message;
          state.loading = false;
        }
      );
  },
});

// export const {} = settingsSlice.actions;

export default settingsSlice.reducer;
