import { apiRedux } from "./api";

export const rAnalogsApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({
    getRAnalogs: build.query({
      query: () => ({
        url: "r_analog/find_all",
      }),
    }),
    getRAnalog: build.mutation({
      query: ({ id, task }) => ({
        url: `r_analog/find_one/`,
        params: { id, task },
      }),
    }),
    addRAnalog: build.mutation({
      query: (body) => ({
        url: `r_analog/add_one`,
        method: "POST",
        body: body,
      }),
    }),
    updateRAnalog: build.mutation({
      query: ({ data, r_id }) => ({
        url: `r_analog/update_one?id=${r_id}`,
        method: "POST",
        body: data,
      }),
    }),
    updateRAnalogSaved: build.mutation({
      query: ({ data, r_id, saved_id }) => ({
        url: `r_analog/update_one_saved?id=${r_id}&saved_id=${saved_id}`,
        method: "POST",
        body: data,
      }),
    }),
    deleteRAnalog: build.mutation({
      query: (r_id) => ({
        url: `r_analog/${r_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddRAnalogMutation,
  useDeleteRAnalogMutation,
  useGetRAnalogMutation,
  useGetRAnalogsQuery,
  useUpdateRAnalogMutation,
  useUpdateRAnalogSavedMutation,
} = rAnalogsApi;
